exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".containerPosition_3RVN2 {\n    position: relative;\n    width: 100%;\n    font-size: 16px;\n}\n\n.pageSize_fjBKv {\n    position: relative;\n    min-height: 1420px;\n}\n\n.paddingSides_349jh {\n    padding: 30px 40px 0px;\n}\n\n.imgWidth_1wpEC {\n    width: 300px;\n}\n\n.paddingBottom_3dMrB {\n    padding-bottom: 20px;\n}\n\n.inputInline_2-Vni {\n    display: inline-block;\n}\n\n.signatureInput_1bdz9 {\n    vertical-align: bottom;\n    margin: 0px !important;\n    width: 30%;\n}\n\n.textCenter_dk258 {\n    text-align: center;\n}\n\n.textRight_C2Nfw {\n    text-align: right;\n}\n\n.alignBottom_2E3hY {\n    vertical-align: bottom !important;\n}\n\n.tableBorder_1IHa_ td {\n    border: 0px !important;\n}\n\n.tablePadding_354ic td {\n    padding: 1px !important;\n}\n\n.labelCell_3VV2x {\n    width: 100px;\n    vertical-align: bottom !important;\n}\n\n.breakPage_1dfIN {\n    page-break-after: always;\n}\n\n.initialSize_19nbm img {\n    max-width: 100px;\n    max-height: 34px;\n    width: auto;\n    height: auto;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_3RVN2 {\n        font-size: 10pt;\n    }\n\n    .displayNone_8SoZK {\n        display: none;\n    }\n\n    .pageSize_fjBKv {\n        min-height: 27.9cm;\n    }\n\n    .footerPosition_2T6JD {\n        bottom: 20pt;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"containerPosition": "containerPosition_3RVN2",
	"pageSize": "pageSize_fjBKv",
	"paddingSides": "paddingSides_349jh",
	"imgWidth": "imgWidth_1wpEC",
	"paddingBottom": "paddingBottom_3dMrB",
	"inputInline": "inputInline_2-Vni",
	"signatureInput": "signatureInput_1bdz9",
	"textCenter": "textCenter_dk258",
	"textRight": "textRight_C2Nfw",
	"alignBottom": "alignBottom_2E3hY",
	"tableBorder": "tableBorder_1IHa_",
	"tablePadding": "tablePadding_354ic",
	"labelCell": "labelCell_3VV2x",
	"breakPage": "breakPage_1dfIN",
	"initialSize": "initialSize_19nbm",
	"displayNone": "displayNone_8SoZK",
	"footerPosition": "footerPosition_2T6JD"
};