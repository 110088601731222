exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".containerPosition_TIsnd {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n.pageSize_2c2f7 {\n    position: relative;\n    min-height: 1420px;\n}\n\n.paddingSides_2Of5l {\n    padding: 20px;\n}\n\n.licensePadding_3KqFx {\n    padding: 10px 30px;\n}\n\n.largePaddingSides_2HBF1 {\n    padding-left: 100px;\n    padding-right: 100px;\n}\n\n.textCenter_25p2B {\n    text-align: center;\n}\n\n.breakPage_2jXE4 {\n    page-break-after: always;\n}\n\n.marginNone_fXALD {\n    margin: 0px;\n}\n\n.smallMargin_2prse {\n    margin: 5px 0px;\n}\n\n.marginBottom_14K3h {\n    margin: 0 0 20px;\n}\n\n.titleFontSize_TwePE {\n    font-size: 24px;\n}\n\n.borderLine_mOn9Z {\n    border-top: 1px solid #000;\n}\n\n.listStyle_1UM4F {\n    list-style: none;\n}\n\n.signatureWrap_3O5Zs {\n    width: 30%;\n    margin: auto;\n}\n\n.paddingRight_26l7b {\n    padding-right: 10px;\n}\n\n.inlineInput_1wKO_ {\n    display: inline-block;\n}\n\n.footerPosition_304Aw {\n    position: absolute;\n    bottom: 10px;\n    width: 100%;\n}\n\n.tableBorder_FLExb td {\n    border: 0px !important;\n}\n\n.tablePadding_2QY5M td {\n    padding: 5px !important;\n}\n\n.tableContainer_3nZ5a {\n    border: 1px solid;\n}\n\n.textRight_3_uRc {\n    text-align: right;\n}\n\n.listPadding_-WNfD li {\n    padding-bottom: 10px;\n}\n\n.alignBottom_PxBMX {\n    vertical-align: bottom !important;\n}\n\n.inputWidth_38-9x {\n    width: 80%;\n}\n\n.overflowHidden_2ATnf {\n    overflow: hidden;\n    border-width: 0px 0px 1px;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_TIsnd {\n        font-size: 10pt;\n    }\n\n    .displayNone_w0WRj {\n        display: none;\n    }\n\n    .pageSize_2c2f7 {\n        min-height: 27.9cm;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"containerPosition": "containerPosition_TIsnd",
	"pageSize": "pageSize_2c2f7",
	"paddingSides": "paddingSides_2Of5l",
	"licensePadding": "licensePadding_3KqFx",
	"largePaddingSides": "largePaddingSides_2HBF1",
	"textCenter": "textCenter_25p2B",
	"breakPage": "breakPage_2jXE4",
	"marginNone": "marginNone_fXALD",
	"smallMargin": "smallMargin_2prse",
	"marginBottom": "marginBottom_14K3h",
	"titleFontSize": "titleFontSize_TwePE",
	"borderLine": "borderLine_mOn9Z",
	"listStyle": "listStyle_1UM4F",
	"signatureWrap": "signatureWrap_3O5Zs",
	"paddingRight": "paddingRight_26l7b",
	"inlineInput": "inlineInput_1wKO_",
	"footerPosition": "footerPosition_304Aw",
	"tableBorder": "tableBorder_FLExb",
	"tablePadding": "tablePadding_2QY5M",
	"tableContainer": "tableContainer_3nZ5a",
	"textRight": "textRight_3_uRc",
	"listPadding": "listPadding_-WNfD",
	"alignBottom": "alignBottom_PxBMX",
	"inputWidth": "inputWidth_38-9x",
	"overflowHidden": "overflowHidden_2ATnf",
	"displayNone": "displayNone_w0WRj"
};