exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".containerPosition_1P8nb {\n    position: relative;\n    width: 100%;\n    font-size: 16px;\n}\n\n.pageSize_3Utx2 {\n    position: relative;\n    min-height: 1420px;\n}\n\n.paddingSides_3C6IV {\n    padding: 50px 70px 0px;\n}\n\n.inputWidth_27DI3 {\n    width: 500px;\n}\n\n.paddingTitle_3hfuv {\n    padding-top: 40px;\n    padding-bottom: 20px;\n}\n.imgWidth_2h_hT {\n    width: 300px;\n}\n\n.textCenter_30iTI {\n    text-align: center;\n}\n\n.inputInline_3_zbL {\n    display: inline-block;\n}\n\n.bottomAlign_3RY_L {\n    vertical-align: bottom;\n}\n\n.marginNone_2DAck {\n    margin: 0px;\n}\n\n.signatureWidth_2Zr4e {\n    width: 400px;\n}\n\n.paddingInput_2ZaOB {\n    padding: 0px 20px;\n}\n\n.paddingTop_2g570 {\n    padding-top: 30px;\n}\n\n.breakPage_32uY- {\n    page-break-after: always;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_1P8nb {\n        font-size: 10pt;\n    }\n\n    .displayNone_2EIWu {\n        display: none;\n    }\n\n    .pageSize_3Utx2 {\n        min-height: 27.9cm;\n    }\n}", ""]);

// exports
exports.locals = {
	"containerPosition": "containerPosition_1P8nb",
	"pageSize": "pageSize_3Utx2",
	"paddingSides": "paddingSides_3C6IV",
	"inputWidth": "inputWidth_27DI3",
	"paddingTitle": "paddingTitle_3hfuv",
	"imgWidth": "imgWidth_2h_hT",
	"textCenter": "textCenter_30iTI",
	"inputInline": "inputInline_3_zbL",
	"bottomAlign": "bottomAlign_3RY_L",
	"marginNone": "marginNone_2DAck",
	"signatureWidth": "signatureWidth_2Zr4e",
	"paddingInput": "paddingInput_2ZaOB",
	"paddingTop": "paddingTop_2g570",
	"breakPage": "breakPage_32uY-",
	"displayNone": "displayNone_2EIWu"
};