exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".containerPosition_1hhdg {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n.textCenter_fpNKa {\n    text-align: center;\n}\n\n.paddingTopContent_1nQr2 {\n    padding-top: 40px;\n}\n\n.paddingTopLogo_3cprk {\n    padding-top: 30px;\n}\n\n.paddingLeftRight_PeP8r {\n    padding-left: 30px;\n    padding-right: 30px;\n}\n\n.paddingTopSection_3FTfi {\n    padding-top: 25px;\n    padding-bottom: 10px;\n}\n\n.paddingToplogo_2jwvM {\n    padding-top: 40px;\n}\n\n.imgWidth_1bOdI {\n    width: 250px;\n}\n\n.textItalic_2XDqh {\n    font-style: italic;\n    font-variant: normal;\n    font-weight: bold;\n    font-size: 22px;\n}\n\n.alignJustify_3oSol {\n    text-align: justify;\n}\n\n.textVariant_MXQRl {\n    font-variant: normal;\n}\n\n.inlineInput_1aq8K {\n    display: inline-block;\n}\n\n.rightPadding_1oJNy {\n    padding-right: 50px;\n}\n\n.borderContainer_lqNrQ {\n    border: 1px solid;\n}\n\n.marginSides_1hqM6 {\n    margin-right: 40px;\n    margin-left: 40px;\n}\n\n.marginTop_Bedlk {\n    margin-top: 40px;\n}\n\n.smallPadding_6K0vS {\n    padding: 10px;\n}\n\n.pageSize_3EUoN {\n    position: relative;\n    min-height: 1420px;\n}\n\n.breakPage_yj4hQ {\n    page-break-after: always;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .paddingTopContent_1nQr2 {\n        padding-top: 10pt;\n    }\n\n    .paddingTopSection_3FTfi {\n        padding-top: 10pt;\n        padding-bottom: 5pt;\n    }\n\n    .containerPosition_1hhdg {\n        font-size: 7pt;\n    }\n\n    .displayNone_3MmHo {\n        display: none;\n    }\n\n    .pageSize_3EUoN {\n        min-height: 27.9cm;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"containerPosition": "containerPosition_1hhdg",
	"textCenter": "textCenter_fpNKa",
	"paddingTopContent": "paddingTopContent_1nQr2",
	"paddingTopLogo": "paddingTopLogo_3cprk",
	"paddingLeftRight": "paddingLeftRight_PeP8r",
	"paddingTopSection": "paddingTopSection_3FTfi",
	"paddingToplogo": "paddingToplogo_2jwvM",
	"imgWidth": "imgWidth_1bOdI",
	"textItalic": "textItalic_2XDqh",
	"alignJustify": "alignJustify_3oSol",
	"textVariant": "textVariant_MXQRl",
	"inlineInput": "inlineInput_1aq8K",
	"rightPadding": "rightPadding_1oJNy",
	"borderContainer": "borderContainer_lqNrQ",
	"marginSides": "marginSides_1hqM6",
	"marginTop": "marginTop_Bedlk",
	"smallPadding": "smallPadding_6K0vS",
	"pageSize": "pageSize_3EUoN",
	"breakPage": "breakPage_yj4hQ",
	"displayNone": "displayNone_3MmHo"
};