exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_3rNE_ {\n    padding: 50px 80px 0px;\n}\n\n.infoContainer_3rNE_ > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.conditionsContainer_2kFkz {\n    padding-top: 5px;\n}\n\n.gapSection20_tlUdU {\n    height: 680px;\n}\n\n.signatureSize_3fUAS img {\n    max-width: 200px;\n    max-height: 80px;\n}\n\n.centerText_12Y8o {\n    text-align: center;\n}\n\n.descriptionText_2MCae {\n    font-size: 15px !important;\n}\n\n.tableSize_9MV_O {\n    width: 100%;\n}\n\n.tableSize_9MV_O td {\n    border: none !important;\n}\n\n.cellWidth_2Llgn {\n    width: 60%;\n}\n\n.textBorder_Ahi23 {\n    border: solid 1px;\n}\n\n.textContainer_20wTm {\n    padding: 10px 0px 100px;\n}\n\n.signatureBox_1XHZt {\n    width: 50%;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_3rNE_",
	"conditionsContainer": "conditionsContainer_2kFkz",
	"gapSection20": "gapSection20_tlUdU",
	"signatureSize": "signatureSize_3fUAS",
	"centerText": "centerText_12Y8o",
	"descriptionText": "descriptionText_2MCae",
	"tableSize": "tableSize_9MV_O",
	"cellWidth": "cellWidth_2Llgn",
	"textBorder": "textBorder_Ahi23",
	"textContainer": "textContainer_20wTm",
	"signatureBox": "signatureBox_1XHZt"
};