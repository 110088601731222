exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_1ubwy {\n    padding: 0 80px;\n}\n\n.divition_1WkWH {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.infoContainer_1ubwy > p {\n    padding: 15px 0;\n    font-weight: 800;\n    font-style: italic;\n    letter-spacing: 1px;\n    line-height: 25px;\n}\n\n.infoContainer_1ubwy > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.gapSection3_3M7vn {\n    height: 240px;\n}\n\n.signatureSize_YA8S9 img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_1ubwy",
	"divition": "divition_1WkWH",
	"gapSection3": "gapSection3_3M7vn",
	"signatureSize": "signatureSize_YA8S9"
};