exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_1nq6b {\n    padding: 0 80px;\n}\n\n.infoContainer_1nq6b > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.conditionsContainer_2KXYh {\n    padding-top: 5px;\n}\n\n.gapSection19_2kflD {\n    height: 100px;\n}\n\n.signatureSize_1eNGL img {\n    max-width: 200px;\n    max-height: 80px;\n}\n\n.centerText_1dbFL {\n    text-align: center;\n}\n\n.descriptionText_2UbMf {\n    font-size: 15px !important;\n}\n\n.titleSize_1FcHi {\n    width: 70%;\n}\n\n.cellSize_1zrGn {\n    width: 80%;\n}\n\n.tablePadding_5O7pF td {\n    padding: 10px;\n}\n\n.tableBorder_21cXL {\n    width: 100%;\n}\n\n.tableBorder_21cXL td {\n    border: none !important;\n}\n\n.inputDisplay_1uzrf {\n    display: none;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_1nq6b",
	"conditionsContainer": "conditionsContainer_2KXYh",
	"gapSection19": "gapSection19_2kflD",
	"signatureSize": "signatureSize_1eNGL",
	"centerText": "centerText_1dbFL",
	"descriptionText": "descriptionText_2UbMf",
	"titleSize": "titleSize_1FcHi",
	"cellSize": "cellSize_1zrGn",
	"tablePadding": "tablePadding_5O7pF",
	"tableBorder": "tableBorder_21cXL",
	"inputDisplay": "inputDisplay_1uzrf"
};