exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".root_xDZUO {\n    margin-top: 10px;\n    width: 100%;\n}\n\n.root_xDZUO > span {\n    margin-left: 5px;\n    color: #df1642;\n}\n\n.error_3JuuQ {\n    margin-top: 3px;\n    color: #df1642;\n    margin-bottom: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"root": "root_xDZUO",
	"error": "error_3JuuQ"
};