exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_NT4TJ {\n    padding: 0 80px;\n}\n\n.divition_3vWaF {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.infoContainer_NT4TJ > p {\n    padding: 30px 0 20px 0;\n    font-weight: 800;\n    letter-spacing: 1px;\n    line-height: 25px;\n    font-style: italic;\n}\n\n.infoContainer_NT4TJ > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.gapSection4_1ZFDI {\n    height: 670px;\n}\n\n.signatureSize_3-BY1 img {\n    max-width: 200px;\n    max-height: 80px;\n}\n", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_NT4TJ",
	"divition": "divition_3vWaF",
	"gapSection4": "gapSection4_1ZFDI",
	"signatureSize": "signatureSize_3-BY1"
};