exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".headerWrap_3ldrc {\n    position: relative;\n    margin-top: 40px;\n}\n\n.headerLogoImg_1fAC5 {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 130px;\n}\n\n.headerTitleWrap_3q8jz {\n    font-size: 14px;\n    z-index: 1;\n}\n", ""]);

// exports
exports.locals = {
	"headerWrap": "headerWrap_3ldrc",
	"headerLogoImg": "headerLogoImg_1fAC5",
	"headerTitleWrap": "headerTitleWrap_3q8jz"
};