exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_1aFc1 {\n    padding: 0 80px;\n}\n\n.infoContainer_1aFc1 > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.sectionSubList_2kgTO {\n    list-style: none;\n    position: relative;\n}\n\n.sectionListChild_1WHWy {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.sectionListChild_1WHWy input {\n    position: absolute;\n    left: 10px;\n    line-height: 20px;\n}\n\n.descriptionConent_2IOvb {\n    letter-spacing: 0.4px;\n    font-size: 16px;\n    line-height: 30px;\n}\n\n.divition_hPS8q {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.gapSection13_1ZcFB {\n    height: 580px;\n}\n\n.alignment_2DCJ6 {\n    padding-top: 30px;\n}\n\n.signatureLeft_3BaNQ {\n    margin: 0 !important;\n}\n\n.datePickerRight_3j60C {\n    margin: 0 0 0 78px !important;\n}\n\n.signatureSize_2cNx8 img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_1aFc1",
	"sectionSubList": "sectionSubList_2kgTO",
	"sectionListChild": "sectionListChild_1WHWy",
	"descriptionConent": "descriptionConent_2IOvb",
	"divition": "divition_hPS8q",
	"gapSection13": "gapSection13_1ZcFB",
	"alignment": "alignment_2DCJ6",
	"signatureLeft": "signatureLeft_3BaNQ",
	"datePickerRight": "datePickerRight_3j60C",
	"signatureSize": "signatureSize_2cNx8"
};