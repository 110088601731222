exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".alignFees_3hjAM {\n    vertical-align: bottom !important;\n    padding: 0 8px !important;\n    font-size: 12px;\n}\n", ""]);

// exports
exports.locals = {
	"alignFees": "alignFees_3hjAM"
};