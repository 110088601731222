exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".agreementPosition_3Ip3X {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n.agreementPositionPdf_2Nc5J {\n    position: relative;\n    width: 100%;\n    font-size: 12pt;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .agreementPosition_3Ip3X {\n        font-size: 7pt;\n    }\n\n    .displayNone_1V5YT {\n        display: none;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"agreementPosition": "agreementPosition_3Ip3X",
	"agreementPositionPdf": "agreementPositionPdf_2Nc5J",
	"displayNone": "displayNone_1V5YT"
};