exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".signature_g0uIS {\n    max-width: 856px;\n    text-align: center;\n    color: #3e3e3e;\n    font-size: small;\n}\n.signature_g0uIS .required_b5uRx {\n    color: red;\n}\n\n.signature_g0uIS textarea {\n    width: 100%;\n    height: 80px;\n}\n\n.signature_g0uIS .buttonContainer_v1yU_ {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    margin-top: 10px;\n}\n\n.rejectModal_Oa33e .body_2sh26 {\n    left: calc(50% - 148px);\n}\n\n.baseBtn_3ntEJ {\n    line-height: 1.5715;\n    position: relative;\n    display: inline-block;\n    font-weight: 400;\n    white-space: nowrap;\n    text-align: center;\n    background-image: none;\n    border: 1px solid transparent;\n    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);\n    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);\n    cursor: pointer;\n    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);\n    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);\n    -webkit-user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n    -ms-touch-action: manipulation;\n    touch-action: manipulation;\n    height: 32px;\n    padding: 4px 15px;\n    font-size: 14px;\n    border-radius: 2px;\n    color: rgba(0, 0, 0, 0.65);\n    background: #fff;\n    border-color: #d9d9d9;\n    outline: 0;\n    color: #fff !important;\n    background-color: #ff349b !important;\n    border-radius: 4px !important;\n    padding: 7px 17px !important;\n    height: auto !important;\n    display: flex !important;\n    align-items: center;\n    font-weight: bold !important;\n}\n.cancelBtn_3s2g0 {\n    margin-right: 50px;\n    background-color: #1e90ff !important;\n}\nbutton.cancelBtn_3s2g0:hover {\n    background-color: #62b1ff !important;\n}\n\n.baseBtn_3ntEJ:hover {\n    background-color: #ff7ec0 !important;\n    color: #fff;\n    border-color: inherit;\n}\n", ""]);

// exports
exports.locals = {
	"signature": "signature_g0uIS",
	"required": "required_b5uRx",
	"buttonContainer": "buttonContainer_v1yU_",
	"rejectModal": "rejectModal_Oa33e",
	"body": "body_2sh26",
	"baseBtn": "baseBtn_3ntEJ",
	"cancelBtn": "cancelBtn_3s2g0"
};