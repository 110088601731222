exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".root_vmw8h {\n    margin-top: 10px;\n    width: 100%;\n}\n\n.test_3ZQQA {\n}\n\n.root_vmw8h > span {\n    margin-left: 5px;\n    color: #df1642;\n}\n\n.error_2i8Cp {\n    margin-top: 3px;\n    color: #df1642;\n    margin-bottom: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"root": "root_vmw8h",
	"test": "test_3ZQQA",
	"error": "error_2i8Cp"
};