exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_1QA4l {\n    padding: 0 10px;\n    font-size: 16px;\n}\n\n.infoContainer_1QA4l > .infoRow_1f-09 > h3,\n.infoContainer_1QA4l > .infoRow_1f-09 > h4 {\n    text-align: center;\n}\n\n.gapSection1_3-98O {\n    height: 320px;\n}\n\n.centered_2HdZx {\n    text-align: center;\n}\n\n.centered_2HdZx h4 {\n    font-weight: 900;\n}\n\n.rowTableContainer_1l228 {\n    padding: 20px 0;\n}\n\n.headerTitle_22ZUG {\n    font-size: 18px;\n    text-align: center;\n    margin-bottom: 0;\n}\n\n.headerTitleInBlock_1rNg1 {\n    font-size: 18px;\n    text-align: center;\n    margin-bottom: 0;\n    display: inline-block;\n}\n.subtitle_3-cg- {\n    text-align: center;\n}\n\n.rowItemWrap_10H_w {\n    margin-bottom: 5px;\n}\n\n.signatureItemContainer_2Dt6a {\n    display: inline-block;\n    width: 100%;\n    font-size: 12px;\n}\n\n.signatureOptOuts_3fU6K {\n    width: 100%;\n    text-align: center;\n}\n\n.signatureOptOuts_3fU6K td {\n    vertical-align: bottom;\n}\n\n.termsInputWrap_3Eb7m {\n    display: inline-block;\n    width: 150px;\n    padding: 0 5px;\n}\n\n.termsInputWrap_3Eb7m > div > div > input {\n    text-align: center;\n}\n.selectInput_1OBn7 {\n    display: inline-block;\n    width: 85px;\n    padding: 0 5px;\n}\n.selectCampuses_1Hb9O {\n    width: 135px;\n    display: inline-block;\n    padding: 0 5px;\n}\n.selectPrograms_3W9mM {\n    width: 235px;\n    display: inline-block;\n    padding: 0 5px;\n}\n.textRight_k4s-R {\n    text-align: right;\n}\n.inputInline_BSRhm {\n    display: inline-block;\n}\n.marginNone_23fs0 {\n    margin: 0px;\n}\n.smallFont_3bUqS {\n    font-size: 12px !important;\n}\n.printTextRight_1L1me {\n    text-align: right;\n}\n.signatureInput_2KAHu {\n    width: 35%;\n}\n.studentInput_25I1B {\n    width: 45%;\n    margin: 0px;\n    vertical-align: bottom;\n    padding-right: 50px;\n}\n", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_1QA4l",
	"infoRow": "infoRow_1f-09",
	"gapSection1": "gapSection1_3-98O",
	"centered": "centered_2HdZx",
	"rowTableContainer": "rowTableContainer_1l228",
	"headerTitle": "headerTitle_22ZUG",
	"headerTitleInBlock": "headerTitleInBlock_1rNg1",
	"subtitle": "subtitle_3-cg-",
	"rowItemWrap": "rowItemWrap_10H_w",
	"signatureItemContainer": "signatureItemContainer_2Dt6a",
	"signatureOptOuts": "signatureOptOuts_3fU6K",
	"termsInputWrap": "termsInputWrap_3Eb7m",
	"selectInput": "selectInput_1OBn7",
	"selectCampuses": "selectCampuses_1Hb9O",
	"selectPrograms": "selectPrograms_3W9mM",
	"textRight": "textRight_k4s-R",
	"inputInline": "inputInline_BSRhm",
	"marginNone": "marginNone_23fs0",
	"smallFont": "smallFont_3bUqS",
	"printTextRight": "printTextRight_1L1me",
	"signatureInput": "signatureInput_2KAHu",
	"studentInput": "studentInput_25I1B"
};