exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_2cVeV {\n    padding: 0 20px;\n}\n\n.divition_2CAMx {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.infoContainer_2cVeV > p {\n    text-align: center;\n    font-style: italic;\n}\n\n.infoContainer_2cVeV > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.tableContainer_2WL_b {\n    padding: 20px 0 0 0;\n}\n\n.advertisement_3Bz3F {\n    text-align: center;\n    font-style: italic;\n    font-weight: 800;\n}\n\n.gapSection11_jQmAA {\n    height: 380px;\n}\n", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_2cVeV",
	"divition": "divition_2CAMx",
	"tableContainer": "tableContainer_2WL_b",
	"advertisement": "advertisement_3Bz3F",
	"gapSection11": "gapSection11_jQmAA"
};