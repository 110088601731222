exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".imageWidth_3_Ey9 {\n    width: 200px;\n}\n\n.container_13NRf {\n    background-color: #ebebeb;\n}\n\n.staffForm_32gDE {\n    width: 80%;\n    margin: auto;\n    background-color: #fff;\n    padding: 20px;\n}\n\naside {\n    width: 20%;\n    display: inline-block;\n    padding: 30px;\n    border-radius: 10px;\n    vertical-align: top;\n}\n\n.backgroundColor_3kkNW {\n    background-color: #fff;\n}\n\n.sideBackgroundColor_1jmgD {\n    background-color: #eee;\n}\n\narticle {\n    width: 100%;\n    margin: auto;\n    display: inline-block;\n}\n\n.lineColor_1NuMk {\n    border-top: 3px solid #337ab7;\n}\n\n.textCenter_RJBHe {\n    text-align: center;\n}\n\n.inputWidth_yUcrv {\n    width: 100%;\n}\n\n.displayNone_WRO8b {\n    display: none;\n}\n\n.containerHeight_2qFDU {\n    min-height: 80em;\n    width: 100%;\n}\n\n.formBorder_1o9VO {\n    border: 1px solid #337ab7;\n    padding: 10px;\n    border-radius: 10px;\n}\n\n.datePicker_2T6kX > div {\n    width: 100%;\n}\n\n.studentContainer_3dpIs {\n    width: 20%;\n}\n\n.paddingTop_2Gatq {\n    padding-top: 50px;\n}\n\n.marginAuto_3qSfd {\n    margin: auto;\n}\n\n.formHeader_hoF0m {\n    position: fixed;\n    z-index: 2;\n    background-color: #fff;\n    width: 100%;\n}\n\n.paddingTopSection_2wy9y {\n    padding-top: 100px;\n}\n\n.smalPaddingTop_1rpKS {\n    padding-top: 100px;\n\n}\n\n.detailsHeader_193e0 {\n    padding: 10px 40px;\n    background: #e9f4ff;\n    margin-bottom: 10px;\n    z-index: 0;\n    margin-top: 200px;\n}\n\n.headerContainer_34EkE {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n\n.borderLine_2Clvz {\n    border-top: 2px solid #337ab7;\n}\n\n.mainContainer_3MEnL {\n    width: 1100px;\n    min-width: 1100px;\n    padding: 0 50px;\n    position: relative;\n    margin-right: auto;\n    margin-left: auto;\n    background-color: #fff;\n}\n\n.colorRed_2wTar {\n    color: #ff0000;\n}\n\n.customRates_1kWJa {\n    padding-right: 20px;\n}", ""]);

// exports
exports.locals = {
	"imageWidth": "imageWidth_3_Ey9",
	"container": "container_13NRf",
	"staffForm": "staffForm_32gDE",
	"backgroundColor": "backgroundColor_3kkNW",
	"sideBackgroundColor": "sideBackgroundColor_1jmgD",
	"lineColor": "lineColor_1NuMk",
	"textCenter": "textCenter_RJBHe",
	"inputWidth": "inputWidth_yUcrv",
	"displayNone": "displayNone_WRO8b",
	"containerHeight": "containerHeight_2qFDU",
	"formBorder": "formBorder_1o9VO",
	"datePicker": "datePicker_2T6kX",
	"studentContainer": "studentContainer_3dpIs",
	"paddingTop": "paddingTop_2Gatq",
	"marginAuto": "marginAuto_3qSfd",
	"formHeader": "formHeader_hoF0m",
	"paddingTopSection": "paddingTopSection_2wy9y",
	"smalPaddingTop": "smalPaddingTop_1rpKS",
	"detailsHeader": "detailsHeader_193e0",
	"headerContainer": "headerContainer_34EkE",
	"borderLine": "borderLine_2Clvz",
	"mainContainer": "mainContainer_3MEnL",
	"colorRed": "colorRed_2wTar",
	"customRates": "customRates_1kWJa"
};