exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_3-Byb {\n    padding: 0 80px;\n}\n\n.infoContainer_3-Byb > div {\n    padding: 10px;\n}\n\n.infoContainer_3-Byb span {\n    font-size: 18px;\n    line-height: 25px;\n    letter-spacing: 1px;\n    text-align: center;\n    padding-bottom: 10px;\n}\n\n.gapSection6_3zZ3g {\n    height: 590px;\n}\n\n.initialBoxImage_zRkFY img {\n    max-width: 100px;\n    max-height: 34px;\n    height: auto;\n    width: auto;\n}\n\n.blockInput_Jxege {\n    display: block !important;\n}\n\n\n.signatureSize_3EMi1  img {\n    max-width: 200px;\n    max-height: 80px;\n}\n", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_3-Byb",
	"gapSection6": "gapSection6_3zZ3g",
	"initialBoxImage": "initialBoxImage_zRkFY",
	"blockInput": "blockInput_Jxege",
	"signatureSize": "signatureSize_3EMi1"
};