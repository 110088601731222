exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".sectionSubList_2lszz {\n    list-style: none;\n    position: relative;\n}\n\n.sectionListChild_3PtWP {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.sectionListChild_3PtWP input {\n    position: absolute;\n    left: 10px;\n    line-height: 20px;\n}\n\n.sectionListChild_3PtWP > p {\n    font-size: 12px;\n    margin: 0;\n}\n", ""]);

// exports
exports.locals = {
	"sectionSubList": "sectionSubList_2lszz",
	"sectionListChild": "sectionListChild_3PtWP"
};