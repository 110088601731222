exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_YYPRI {\n    padding: 0 80px;\n}\n\n.infoContainer_YYPRI > div {\n    padding: 10px;\n}\n\n.infoContainer_YYPRI span {\n    font-size: 18px;\n    line-height: 25px;\n    letter-spacing: 1px;\n    text-align: center;\n    padding-bottom: 10px;\n}\n\n.gapSection6_3JN8Q {\n    height: 590px;\n}\n\n.initialBoxImage_ufRQi img {\n    max-width: 100px;\n    max-height: 34px;\n    height: auto;\n    width: auto;\n}\n\n.blockInput_1tK9e {\n    display: block !important;\n}\n\n.signatureSize_3FV1P img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_YYPRI",
	"gapSection6": "gapSection6_3JN8Q",
	"initialBoxImage": "initialBoxImage_ufRQi",
	"blockInput": "blockInput_1tK9e",
	"signatureSize": "signatureSize_3FV1P"
};