exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".initialContainer_qbybo {\n    padding: 5px 0;\n    border: 1.5px solid #1e1e1e;\n    display: inline-block;\n    width: 286px;\n}\n\n.alignment_3hpWW {\n    vertical-align: bottom;\n}\n\n.initialContainerNoBorder_1ALPo {\n    padding: 5px 0;\n    display: inline-block;\n    width: 286px;\n}\n\n.initialContainerNoBorder_1ALPo img {\n    max-width: 100px;\n    max-height: 34px;\n    height: auto;\n    width: auto;    \n}\n", ""]);

// exports
exports.locals = {
	"initialContainer": "initialContainer_qbybo",
	"alignment": "alignment_3hpWW",
	"initialContainerNoBorder": "initialContainerNoBorder_1ALPo"
};