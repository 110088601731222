exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".header_-k6as {\n    text-align: center;\n    width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"header": "header_-k6as"
};