exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_HwEgE {\n    padding: 0 80px;\n}\n\n.divition_3S5Wt {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.infoContainer_HwEgE > p {\n    font-size: 16px;\n    line-height: 25px;\n    letter-spacing: 0.4px;\n}\n\n.infoContainer_HwEgE > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.noPaddingBottom_16XqW {\n    padding-bottom: 0 !important;\n}\n\n.titleBold_CaU43 {\n    font-weight: 800;\n}\n\n.gapSection18_3aW_2 {\n    height: 350px;\n}\n\n.initialBox_1yZsJ {\n    width: 150px;\n    height: 50px;\n}\n\n.initialBox_1yZsJ img {\n    max-width: 150px;\n    max-height: 50px;\n    height: auto;\n    width: auto;\n}\n\n.initialBox_1yZsJ button {\n    width: 50px;\n    height: 25px;\n    top: 12px;\n    left: 45px;\n}\n\n.signatureLeft_3cF33 {\n    margin: 0 !important;\n}\n\n.dateRight_aOFx3 {\n    margin: 0 0 0 10px !important;\n}\n\n.alignmentBottom_1LVVm {\n    padding-bottom: 10px;\n}\n\n.signatureSize_2a99d img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_HwEgE",
	"divition": "divition_3S5Wt",
	"noPaddingBottom": "noPaddingBottom_16XqW",
	"titleBold": "titleBold_CaU43",
	"gapSection18": "gapSection18_3aW_2",
	"initialBox": "initialBox_1yZsJ",
	"signatureLeft": "signatureLeft_3cF33",
	"dateRight": "dateRight_aOFx3",
	"alignmentBottom": "alignmentBottom_1LVVm",
	"signatureSize": "signatureSize_2a99d"
};