exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".containerPosition_2YbP0 {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n.breakPage_gcx60 {\n    page-break-after: always;\n}\n\n.pageSize_2cKPv {\n    position: relative;\n    min-height: 1420px;\n    padding: 30px 50px;\n}\n\n.infoContainer_2kA28 > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.referalContainer_2l6T6 {\n    padding-top: 30px;\n}\n\n.sectionSubList_FxA0b {\n    list-style: none;\n    position: relative;\n}\n\n.sectionListChild_foKo2 {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.sectionListChild_foKo2 input {\n    position: absolute;\n    left: 10px;\n    line-height: 20px;\n}\n\n.submitContent_12Fj0 {\n    padding-top: 10px;\n    font-weight: 800;\n    font-style: italic;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_2YbP0 {\n        font-size: 10pt;\n    }\n\n    .displayNone_ngUcw {\n        display: none;\n    }\n\n    .pageSize_2cKPv {\n        min-height: 27.9cm;\n    }\n}", ""]);

// exports
exports.locals = {
	"containerPosition": "containerPosition_2YbP0",
	"breakPage": "breakPage_gcx60",
	"pageSize": "pageSize_2cKPv",
	"infoContainer": "infoContainer_2kA28",
	"referalContainer": "referalContainer_2l6T6",
	"sectionSubList": "sectionSubList_FxA0b",
	"sectionListChild": "sectionListChild_foKo2",
	"submitContent": "submitContent_12Fj0",
	"displayNone": "displayNone_ngUcw"
};