exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".headerTitle_1dglg {\n    font-size: 25px;\n    text-align: center;\n    margin-bottom: 0;\n    position: relative;\n    margin-top: 40px;\n}\n.rowItemTitle_29qP9 {\n    font-size: 14px;\n    font-weight: bold;\n    text-align: center;\n    background: #ccc;\n    border-top: 1px solid;\n    margin: 20px 0;\n}\n.rowItemWrap_1X22y {\n    margin-bottom: 5px;\n}\n\n.pointInputWrap_2yqbB {\n    display: inline-block;\n}\n\n.tableWithdraw_2NuD2 {\n    width: 100%;\n    text-align: center;\n    border: 1px solid #d6d6d6;\n}\n.tableWithdraw_2NuD2 th p {\n    text-align: center;\n    margin: 0;\n}\n\n.tableWithdraw_2NuD2 td {\n    width: 50%;\n}\n\n.noManhatan_29Jx2 {\n    height: 181px;\n}\n\n.gapSection2_376D0 {\n    height: 250px;\n}", ""]);

// exports
exports.locals = {
	"headerTitle": "headerTitle_1dglg",
	"rowItemTitle": "rowItemTitle_29qP9",
	"rowItemWrap": "rowItemWrap_1X22y",
	"pointInputWrap": "pointInputWrap_2yqbB",
	"tableWithdraw": "tableWithdraw_2NuD2",
	"noManhatan": "noManhatan_29Jx2",
	"gapSection2": "gapSection2_376D0"
};