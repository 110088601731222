exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_1WkTa {\n    padding: 0 80px;\n}\n\n.infoContainer_1WkTa .textPadding_2E3Rk {\n    padding: 15px 0 40px 0;\n}\n\n.rowLeft_1Zi7f {\n    float: left;\n    width: 30%;\n}\n\n.gapSection1_3_YsI {\n    height: 480px;\n}\n", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_1WkTa",
	"textPadding": "textPadding_2E3Rk",
	"rowLeft": "rowLeft_1Zi7f",
	"gapSection1": "gapSection1_3_YsI"
};