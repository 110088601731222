exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mainContainer_4exQQ {\n    width: 1100px;\n    min-width: 1100px;\n    position: relative;\n    margin-right: auto;\n    margin-left: auto;\n    background-color: #fff;\n}\n\n.headerContainer_1OVQx {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n\n.detailsHeader_1H56q {\n    width: 100%;\n    padding: 10px 40px;\n    background: #e9f4ff;\n    margin-bottom: 10px;\n    z-index: 0;\n}", ""]);

// exports
exports.locals = {
	"mainContainer": "mainContainer_4exQQ",
	"headerContainer": "headerContainer_1OVQx",
	"detailsHeader": "detailsHeader_1H56q"
};