exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_1FesY {\n    padding: 0 80px;\n}\n\n.divition_2yeni {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.infoContainer_1FesY > p {\n    padding: 45px 0 15px 0;\n    font-weight: 800;\n    font-style: italic;\n    letter-spacing: 1px;\n    line-height: 25px;\n}\n\n.infoContainer_1FesY > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.gapSection3_34nuw {\n    height: 640px;\n}\n\n.signatureSize_34kK6 img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_1FesY",
	"divition": "divition_2yeni",
	"gapSection3": "gapSection3_34nuw",
	"signatureSize": "signatureSize_34kK6"
};