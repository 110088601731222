exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_2W_g7 {\n    padding: 0 80px;\n}\n\n.infoContainer_2W_g7 > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.sectionSubList_3UGrt {\n    list-style: none;\n    position: relative;\n}\n\n.sectionListChild_MSAg6 {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.sectionListChild_MSAg6 input {\n    position: absolute;\n    left: 10px;\n    line-height: 20px;\n}\n\n.descriptionConent_1bnSm {\n    letter-spacing: 0.4px;\n    line-height: 25px;\n}\n\n.descriptionConent_1bnSm > ol > li {\n    padding-bottom: 10px;\n}\n\n.divition_1Gp41 {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.allWidth_3ImUr {\n    width: 100% !important;\n}\n\n.initialBox_Npf2L {\n    width: 150px;\n    height: 60px;\n}\n\n.initialBox_Npf2L img {\n    max-width: 150px;\n    max-height: 60px;\n    height: auto;\n    width: auto;\n}\n\n.initialBox_Npf2L button {\n    width: 60px;\n    height: 30px;\n    top: 16px;\n    left: 44px;\n}\n\n.blockInput_1l2NA {\n    display: block !important;\n}\n\n.signatureSize_XzLcx img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_2W_g7",
	"sectionSubList": "sectionSubList_3UGrt",
	"sectionListChild": "sectionListChild_MSAg6",
	"descriptionConent": "descriptionConent_1bnSm",
	"divition": "divition_1Gp41",
	"allWidth": "allWidth_3ImUr",
	"initialBox": "initialBox_Npf2L",
	"blockInput": "blockInput_1l2NA",
	"signatureSize": "signatureSize_XzLcx"
};