exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_1hvmo {\n    padding: 0 80px;\n}\n\n.divition_EAyHf {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.infoContainer_1hvmo > p {\n    padding: 30px 0 20px 0;\n    font-weight: 800;\n    letter-spacing: 1px;\n    line-height: 25px;\n    font-style: italic;\n}\n\n.infoContainer_1hvmo > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.gapSection4_3HWd1 {\n    height: 380px;\n}\n\n.signatureSize_36Uc2 img {\n    max-width: 200px;\n    max-height: 80px;\n}\n", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_1hvmo",
	"divition": "divition_EAyHf",
	"gapSection4": "gapSection4_3HWd1",
	"signatureSize": "signatureSize_36Uc2"
};