exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_1EKNN {\n    padding: 0 20px;\n}\n\n.infoContainer_1EKNN > h3 {\n    text-align: center;\n    font-weight: 800;\n    padding-bottom: 10px;\n}\n\n.arbitrationAgreement_vnMNL {\n    display: inline-block;\n    padding: 0 5px;\n}\n\n.arbitrationContainer_1EJEm span {\n    font-size: 16px;\n}\n\n.sectionAgreement_251o4 {\n    padding: 15px 0;\n}\n\n.gapSection5_2MFeQ {\n    height: 210px;\n}\n\n.initialBoxImage_30Sq5 img {\n    max-width: 100px;\n    max-height: 34px;\n    height: auto;\n    width: auto;    \n}\n", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_1EKNN",
	"arbitrationAgreement": "arbitrationAgreement_vnMNL",
	"arbitrationContainer": "arbitrationContainer_1EJEm",
	"sectionAgreement": "sectionAgreement_251o4",
	"gapSection5": "gapSection5_2MFeQ",
	"initialBoxImage": "initialBoxImage_30Sq5"
};