exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".schoolPerformanceFact_1ynen input[type] {\n    height: 25px;\n    line-height: 32px;\n    font-size: 14px;\n    padding: 0 5px;\n    font-family: inherit;\n    color: #000000;\n    background-color: #fff;\n    background-image: none;\n    border: 0;\n    border-bottom: 1px solid #ccc;\n}\n\n.schoolPerformanceFact_1ynen select {\n    height: 28px;\n    line-height: 32px;\n    font-size: 14px;\n    padding: 0 5px;\n    font-family: inherit;\n    color: #000000;\n    background-color: #fff;\n    background-image: none;\n    border: 1px solid #ccc;\n}\n\n.schoolPerformanceFact_1ynen input:disabled {\n    background-color: #fff !important;\n    color: #000000 !important;\n    opacity: 1 !important;\n}\n\n.schoolPerformanceFact_1ynen select:disabled {\n    background-color: #fff !important;\n    color: #000000 !important;\n    opacity: 1 !important;\n}\n.grayLine_3qH4I {\n    height: 20px;\n    width: 100%;\n    position: relative;\n    margin-bottom: 40px;\n}\n.grayLine_3qH4I:before {\n    content: \"\";\n    position: absolute;\n    display: block;\n    height: 30px;\n    background-color: #ebebeb;\n    width: calc(100% + 120px);\n    left: -60px;\n    top: calc(50% - 10px);\n}\n", ""]);

// exports
exports.locals = {
	"schoolPerformanceFact": "schoolPerformanceFact_1ynen",
	"grayLine": "grayLine_3qH4I"
};