exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".containerPosition_1WBlq {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n.pageSize_2qplI {\n    position: relative;\n    min-height: 1420px;\n    padding-right: 60px;\n    padding-left: 60px;\n}\n\n.imageLogo_1W3BM {\n    width: 250px;\n}\n\n.textRight_2RLX2 {\n    text-align: right;\n}\n\n.borderContainer_LiNKz {\n    border: 1px solid black;\n    padding-right: 5%;\n}\n\n.paddintTopContent_eSttV {\n    padding-top: 50px;\n}\n\n.textCenter_Oj3Pw {\n    text-align: center;\n}\n\n.tableBorder_2-GNa td {\n    border: 0px !important;\n}\n\n.tableAlign_qwN3Q td {\n    vertical-align: bottom !important;\n}\n\n.cellWidth_3F3Ib {\n    width: 180px;\n}\n\n.smallCellWidth_2th0c {\n    width: 100px;\n}\n\n.smallPaddingTop_34fBz {\n    padding-top: 20px;\n}\n\n.cardBorder_zbpz3 {\n    border: 5px solid #000;\n}\n\n.schoolCell_3CdwW {\n    width: 110px;\n}\n\n.yearCell_1Mx6x {\n    width: 130px;\n}\n\n.marginTop_10gGw {\n    margin-top: 30px;\n}\n\n.inlineInput_H84hY {\n    display: inline-block;\n}\n\n.marginNone_2t20z {\n    margin: 0px;\n}\n\n.studentCell_2qSfz {\n    width: 140px;\n}\n\n.dateCell_38Dtf {\n    width: 50px;\n}\n\n.signatureCell_Sn_z3 {\n    width: 500px;\n}\n\n.breakPage_DWxk4 {\n    page-break-after: always;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_1WBlq {\n        font-size: 10pt;\n    }\n\n    .displayNone_3onfl {\n        display: none;\n    }\n\n    .pageSize_2qplI {\n        min-height: 27.9cm;\n        padding-right: 20pt;\n        padding-left: 20pt;\n    }\n\n    .imageLogo_1W3BM {\n        width: 150px;\n    }\n\n    .signatureCell_Sn_z3 {\n        width: 150pt;\n    }\n\n    .marginTop_10gGw {\n        margin-top: 10pt;\n    }\n\n    .headFont_14L4B {\n        font-size: 20pt;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"containerPosition": "containerPosition_1WBlq",
	"pageSize": "pageSize_2qplI",
	"imageLogo": "imageLogo_1W3BM",
	"textRight": "textRight_2RLX2",
	"borderContainer": "borderContainer_LiNKz",
	"paddintTopContent": "paddintTopContent_eSttV",
	"textCenter": "textCenter_Oj3Pw",
	"tableBorder": "tableBorder_2-GNa",
	"tableAlign": "tableAlign_qwN3Q",
	"cellWidth": "cellWidth_3F3Ib",
	"smallCellWidth": "smallCellWidth_2th0c",
	"smallPaddingTop": "smallPaddingTop_34fBz",
	"cardBorder": "cardBorder_zbpz3",
	"schoolCell": "schoolCell_3CdwW",
	"yearCell": "yearCell_1Mx6x",
	"marginTop": "marginTop_10gGw",
	"inlineInput": "inlineInput_H84hY",
	"marginNone": "marginNone_2t20z",
	"studentCell": "studentCell_2qSfz",
	"dateCell": "dateCell_38Dtf",
	"signatureCell": "signatureCell_Sn_z3",
	"breakPage": "breakPage_DWxk4",
	"displayNone": "displayNone_3onfl",
	"headFont": "headFont_14L4B"
};