exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".root_2hm1J {\n    width: 100%;\n    border-collapse: collapse;\n    margin: 0;\n    font-size: 12px;\n    text-align: center;\n}\n\n.box_3aCW6 {\n    width: 100px;\n    height: 34px;\n    border-bottom: 1px solid #ccc;\n    position: relative;\n}\n\n.button_3wGdx {\n    width: 50px;\n    height: 20px;\n    position: absolute;\n    background-color: #1919ff;\n    color: white;\n    margin: auto;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n}\n\n.error_bSAp7 {\n    margin-top: 3px;\n    color: red;\n    margin-bottom: 5px;\n}\n\n.underline_J0b54 {\n    border-bottom: 2px solid #191919;\n}\n.box_3aCW6 img {\n    max-width: 150px;\n    max-height: 50px;\n    height: auto;\n    width: auto;\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    margin-left: auto;\n    margin-right: auto;\n}\n", ""]);

// exports
exports.locals = {
	"root": "root_2hm1J",
	"box": "box_3aCW6",
	"button": "button_3wGdx",
	"error": "error_bSAp7",
	"underline": "underline_J0b54"
};