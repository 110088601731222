exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".containerPosition_2iHqm {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n.pageSize_XPu52 {\n    position: relative;\n    min-height: 1420px;\n    padding: 40px 60px;\n}\n\n.textCenter_2id2u {\n    text-align: center;\n}\n\n.imgWidth_utm3B {\n    width: 250px;\n}\n\n.paddingTop_12u47 {\n    padding-top: 30px;\n}\n\n.paddingTable_289Ty td {\n    padding: 0px 5px !important;\n}\n\n.cellLeft_i0WVq {\n    text-align: left;\n    vertical-align: middle !important;\n}\n\n.inlineInput_1sAp3 {\n    display: inline-block;\n}\n\n.signatureWidth_FvUBG {\n    width: 30%;\n    vertical-align: bottom;\n    margin: 0px;\n}\n\n.paddingLeft_2iX6m {\n    padding-left: 80px;\n}\n\n.footerPosition_2lPBd {\n    position: absolute;\n    width: 100%;\n    bottom: 40px;\n}\n\n.breakPage_2cqhy {\n    page-break-after: always;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_2iHqm {\n        font-size: 10pt;\n    }\n\n    .displayNone_1nbF9 {\n        display: none;\n    }\n\n    .pageSize_XPu52 {\n        min-height: 27.9cm;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"containerPosition": "containerPosition_2iHqm",
	"pageSize": "pageSize_XPu52",
	"textCenter": "textCenter_2id2u",
	"imgWidth": "imgWidth_utm3B",
	"paddingTop": "paddingTop_12u47",
	"paddingTable": "paddingTable_289Ty",
	"cellLeft": "cellLeft_i0WVq",
	"inlineInput": "inlineInput_1sAp3",
	"signatureWidth": "signatureWidth_FvUBG",
	"paddingLeft": "paddingLeft_2iX6m",
	"footerPosition": "footerPosition_2lPBd",
	"breakPage": "breakPage_2cqhy",
	"displayNone": "displayNone_1nbF9"
};