exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".imgWidth_2_f94 {\n    width: 250px;\n}\n\n.textCenter_iI29Y {\n    text-align: center;\n}\n\n.containertPosition_1pnHL {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n.pageSize_1QSRH {\n    position: relative;\n    min-height: 1420px;\n}\n\n.paddingTopLogo_1A9I4 {\n    padding-top: 40px;\n}\n\n.inlineStudentInitials_1c1WU {\n    display: inline-block;\n}\n\n.initialSize_25fg1 img {\n    max-width: 100px;\n    max-height: 34px;\n    width: auto;\n    height: auto;\n}\n\n.paddingContentTop_1SA17 {\n    padding-top: 20px;\n}\n\n.paddingSignatureRigth_ZwBWb {\n    padding-right: 90px;\n}\n\n.dateSignatureWidth_1bVR8 {\n    width: 78%;\n    display: inline-block;\n}\n\n.footerPosition_19dJQ {\n    position: absolute;\n    bottom: 0px;\n    padding-bottom: 30px;\n    width: 100%;\n}\n\n.inlineInput_2Wh36 {\n    display: inline-block;\n    width: 70%;\n}\n\n.breakPage_PrxLv {\n    page-break-after: always;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_Y4o18 {\n        font-size: 7pt;\n    }\n\n    .pageSize_1QSRH {\n        min-height: 27.9cm;\n    }\n\n    .paddingTopLogo_1A9I4 {\n        padding-top: 10pt;\n    }\n\n    .imgWidth_2_f94 {\n        width: 200pt;\n    }\n\n    .displayNone_1-tso {\n        display: none;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"imgWidth": "imgWidth_2_f94",
	"textCenter": "textCenter_iI29Y",
	"containertPosition": "containertPosition_1pnHL",
	"pageSize": "pageSize_1QSRH",
	"paddingTopLogo": "paddingTopLogo_1A9I4",
	"inlineStudentInitials": "inlineStudentInitials_1c1WU",
	"initialSize": "initialSize_25fg1",
	"paddingContentTop": "paddingContentTop_1SA17",
	"paddingSignatureRigth": "paddingSignatureRigth_ZwBWb",
	"dateSignatureWidth": "dateSignatureWidth_1bVR8",
	"footerPosition": "footerPosition_19dJQ",
	"inlineInput": "inlineInput_2Wh36",
	"breakPage": "breakPage_PrxLv",
	"containerPosition": "containerPosition_Y4o18",
	"displayNone": "displayNone_1-tso"
};