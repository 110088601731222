exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_2PXd7 {\n    padding: 0 80px;\n}\n\n.divition_3C7AZ {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.infoContainer_2PXd7 > div > p {\n    text-align: center;\n    font-weight: 800;\n    font-style: italic;\n}\n\n.infoContainer_2PXd7 > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.checkBoxContent_mS1UG {\n    padding-left: 10px;\n}\n\n.creditContainer_1vWoj {\n    padding-bottom: 15px;\n}\n.creditContainer_1vWoj > h4 {\n    display: inline;\n    font-size: 17.5px;\n    font-weight: 800;\n}\n\n.creditContainer_1vWoj > span,\n.continue_HHn2D {\n    font-style: italic;\n    font-size: 13px;\n}\n\n.gapSection9_13pnH {\n    height: 150px;\n}\n", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_2PXd7",
	"divition": "divition_3C7AZ",
	"checkBoxContent": "checkBoxContent_mS1UG",
	"creditContainer": "creditContainer_1vWoj",
	"continue": "continue_HHn2D",
	"gapSection9": "gapSection9_13pnH"
};