exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".condition_1P3BQ {\n    line-height: 22px;\n    letter-spacing: 0.7px;\n    padding: 0 0 30px 10px;\n}\n\n.subtitle_1Iryn {\n    font-weight: 800;\n    text-decoration: underline;\n}\n", ""]);

// exports
exports.locals = {
	"condition": "condition_1P3BQ",
	"subtitle": "subtitle_1Iryn"
};