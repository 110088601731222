exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_25RO_ {\n    padding: 0 80px;\n}\n\n.divition_218aP {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.infoContainer_25RO_ > p {\n    text-align: center;\n    font-weight: 800;\n    font-style: italic;\n}\n\n.infoContainer_25RO_ > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.noPaddingBottom_1ZfsD {\n    padding-bottom: 0 !important;\n}\n\n.noPaddingBottom_1ZfsD > [class*=\"rowCondition\"] {\n    padding-top: 0;\n}\n\n.titleBold_1Iwg9 {\n    font-weight: 800;\n}\n\n.gapSection12_2Pq7K {\n    height: 30px;\n}\n\n.alignment_1SyNa {\n    text-align: center;\n}\n\n.signatureSize_-ASjX img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_25RO_",
	"divition": "divition_218aP",
	"noPaddingBottom": "noPaddingBottom_1ZfsD",
	"titleBold": "titleBold_1Iwg9",
	"gapSection12": "gapSection12_2Pq7K",
	"alignment": "alignment_1SyNa",
	"signatureSize": "signatureSize_-ASjX"
};