exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_2B7j1 {\n    padding: 0 80px;\n}\n\n.infoContainer_2B7j1 .textPadding_h_WhW {\n    padding: 15px 0 40px 0;\n}\n\n.rowLeft_k8Tx- {\n    float: left;\n    width: 30%;\n}\n\n.gapSection1_2QIxR {\n    height: 480px;\n}\n", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_2B7j1",
	"textPadding": "textPadding_h_WhW",
	"rowLeft": "rowLeft_k8Tx-",
	"gapSection1": "gapSection1_2QIxR"
};