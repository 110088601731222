exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".imgWidth_1zUbG {\n    width: 250px;\n}\n\n.textCenter_1YbqD {\n    text-align: center;\n}\n\n.containertPosition_-B4vz {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n.pageSize_2lz_Q {\n    position: relative;\n    min-height: 1420px;\n}\n\n.paddingTopLogo_2sqKG {\n    padding-top: 40px;\n}\n\n.inlineStudentInitials_1XW_s {\n    display: inline-block;\n}\n\n.initialSize_24_Ie img {\n    max-width: 100px;\n    max-height: 34px;\n    width: auto;\n    height: auto;\n}\n\n.paddingContentTop_13ZGw {\n    padding-top: 20px;\n}\n\n.paddingSignatureRigth_3-y7N {\n    padding-right: 90px;\n}\n\n.dateSignatureWidth_2AHwM {\n    width: 78%;\n    display: inline-block;\n}\n\n.footerPosition_2Z5NX {\n    position: absolute;\n    bottom: 0px;\n    padding-bottom: 30px;\n    width: 100%;\n}\n\n.inlineInput_2EVwo {\n    display: inline-block;\n    width: 70%;\n}\n\n.breakPage_2od3t {\n    page-break-after: always;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_3dPFt {\n        font-size: 7pt;\n    }\n\n    .pageSize_2lz_Q {\n        min-height: 27.9cm;\n    }\n\n    .paddingTopLogo_2sqKG {\n        padding-top: 10pt;\n    }\n\n    .imgWidth_1zUbG {\n        width: 200pt;\n    }\n\n    .displayNone_2ASg3 {\n        display: none;\n    }\n}\n\n.inputDisplay_1xTJz {\n    display: none;\n}\n\n.customRadioWeeks_1_lP9 {\n    height: 15px !important;\n    margin-right: 5px !important;\n    width: 15px !important;\n}\n\n.customRadioWeeks_1_lP9 > span {\n    vertical-align: text-bottom;\n}", ""]);

// exports
exports.locals = {
	"imgWidth": "imgWidth_1zUbG",
	"textCenter": "textCenter_1YbqD",
	"containertPosition": "containertPosition_-B4vz",
	"pageSize": "pageSize_2lz_Q",
	"paddingTopLogo": "paddingTopLogo_2sqKG",
	"inlineStudentInitials": "inlineStudentInitials_1XW_s",
	"initialSize": "initialSize_24_Ie",
	"paddingContentTop": "paddingContentTop_13ZGw",
	"paddingSignatureRigth": "paddingSignatureRigth_3-y7N",
	"dateSignatureWidth": "dateSignatureWidth_2AHwM",
	"footerPosition": "footerPosition_2Z5NX",
	"inlineInput": "inlineInput_2EVwo",
	"breakPage": "breakPage_2od3t",
	"containerPosition": "containerPosition_3dPFt",
	"displayNone": "displayNone_2ASg3",
	"inputDisplay": "inputDisplay_1xTJz",
	"customRadioWeeks": "customRadioWeeks_1_lP9"
};