exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".root_rhJ0R {\n    display: inline-block;\n}\n", ""]);

// exports
exports.locals = {
	"root": "root_rhJ0R"
};