exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".imgWidth_3lh6m {\n    width: 250px;\n}\n\n.textCenter_32FXX {\n    text-align: center;\n}\n\n.containertPosition_XWqmi {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n.pageSize_1l3px {\n    position: relative;\n    min-height: 1420px;\n}\n\n.paddingTopLogo_PEg5F {\n    padding-top: 40px;\n}\n\n.inlineStudentInitials_11dVE {\n    display: inline-block;\n}\n\n.initialSize_3pQ1n img {\n    max-width: 100px;\n    max-height: 34px;\n    width: auto;\n    height: auto;\n}\n\n.paddingContentTop_2qnNS {\n    padding-top: 20px;\n}\n\n.paddingSignatureRigth_36ySb {\n    padding-right: 90px;\n}\n\n.dateSignatureWidth_1rD77 {\n    width: 78%;\n    display: inline-block;\n}\n\n.footerPosition_2NYT3 {\n    position: absolute;\n    bottom: 0px;\n    padding-bottom: 30px;\n    width: 100%;\n}\n\n.inlineInput_3jpkd {\n    display: inline-block;\n    width: 70%;\n}\n\n.breakPage_1uYAL {\n    page-break-after: always;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_3N1nP {\n        font-size: 7pt;\n    }\n\n    .pageSize_1l3px {\n        min-height: 27.9cm;\n    }\n\n    .paddingTopLogo_PEg5F {\n        padding-top: 10pt;\n    }\n\n    .imgWidth_3lh6m {\n        width: 200pt;\n    }\n\n    .displayNone_3ClQM {\n        display: none;\n    }\n}\n\n.paddingTop_2udII {\n    padding-top: 30px;\n}\n", ""]);

// exports
exports.locals = {
	"imgWidth": "imgWidth_3lh6m",
	"textCenter": "textCenter_32FXX",
	"containertPosition": "containertPosition_XWqmi",
	"pageSize": "pageSize_1l3px",
	"paddingTopLogo": "paddingTopLogo_PEg5F",
	"inlineStudentInitials": "inlineStudentInitials_11dVE",
	"initialSize": "initialSize_3pQ1n",
	"paddingContentTop": "paddingContentTop_2qnNS",
	"paddingSignatureRigth": "paddingSignatureRigth_36ySb",
	"dateSignatureWidth": "dateSignatureWidth_1rD77",
	"footerPosition": "footerPosition_2NYT3",
	"inlineInput": "inlineInput_3jpkd",
	"breakPage": "breakPage_1uYAL",
	"containerPosition": "containerPosition_3N1nP",
	"displayNone": "displayNone_3ClQM",
	"paddingTop": "paddingTop_2udII"
};