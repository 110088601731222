exports = module.exports = require("../../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* \n.root {\n    position: absolute;\n    opacity: 0;\n}\n\n.root + label {\n    position: relative;\n    cursor: pointer;\n    padding: 0;\n} */\n\n.root_2LR-5 + label:before {\n    content: \"\";\n    margin-right: 10px;\n    display: inline-block;\n    vertical-align: text-top;\n    width: 20px;\n    height: 20px;\n    background: white;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.root_2LR-5:hover + label:before {\n    background: #275efe;\n}\n\n.root_2LR-5:focus + label:before {\n    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);\n}\n\n.root_2LR-5:checked + label:before {\n    background: #275efe;\n}\n\n.root_2LR-5:disabled + label {\n    color: #000000;\n    cursor: auto;\n}\n\n.root_2LR-5:disabled + label:before {\n    box-shadow: none;\n    background: #ddd;\n    color: #000000;\n}\n\n.root_2LR-5:checked + label:after {\n    content: \"\";\n    position: absolute;\n    left: 5px;\n    top: 9px;\n    background: white;\n    width: 2px;\n    height: 2px;\n    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,\n        4px -8px 0 white;\n    transform: rotate(45deg);\n    -webkit-transform: rotate(45deg); /* wkhtmltopdf uses this one */\n}\n", ""]);

// exports
exports.locals = {
	"root": "root_2LR-5"
};