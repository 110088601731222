exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".containerPosition_21HEA {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n.pageSize_1tgLJ {\n    position: relative;\n    min-height: 1420px;\n}\n\n.breakPage_1YcGP {\n    page-break-after: always;\n}\n\n.paddingSides_Zyeyr {\n    padding-left: 50px;\n    padding-right: 50px;\n}\n\n.paddingTop_Er1zs {\n    padding-top: 30px;\n}\n\n.textCenter_yn8aP {\n    text-align: center;\n}\n\n.tablePadding_13-NG td {\n    padding: 0px !important;\n}\n\n.tableVerticalAlign_3_oYs td {\n    vertical-align: middle !important;\n}\n\n.marginTop_3CqUt {\n    margin-top: 30px;\n}\n\n.cellHeight_2Zduw {\n    height: 34px;\n}\n\n.inlineSignature_c8hFR {\n    display: inline-block;\n    margin: 0px;\n    vertical-align: bottom;\n    width: 40%;\n}\n\n.largePaddingTop_1iPCv {\n    padding-top: 50px;\n}\n\n.footerPosition_3Qif_ {\n    position: absolute;\n    bottom: 30px;\n    width: 100%;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_21HEA {\n        font-size: 10pt;\n    }\n\n    .displayNone_1_tjD {\n        display: none;\n    }\n\n    .pageSize_1tgLJ {\n        min-height: 27.9cm;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"containerPosition": "containerPosition_21HEA",
	"pageSize": "pageSize_1tgLJ",
	"breakPage": "breakPage_1YcGP",
	"paddingSides": "paddingSides_Zyeyr",
	"paddingTop": "paddingTop_Er1zs",
	"textCenter": "textCenter_yn8aP",
	"tablePadding": "tablePadding_13-NG",
	"tableVerticalAlign": "tableVerticalAlign_3_oYs",
	"marginTop": "marginTop_3CqUt",
	"cellHeight": "cellHeight_2Zduw",
	"inlineSignature": "inlineSignature_c8hFR",
	"largePaddingTop": "largePaddingTop_1iPCv",
	"footerPosition": "footerPosition_3Qif_",
	"displayNone": "displayNone_1_tjD"
};