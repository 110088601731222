exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_1hJ5I {\n    padding: 50px 80px 0px;\n}\n\n.infoContainer_1hJ5I > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.conditionsContainer_3vMmb {\n    padding-top: 5px;\n}\n\n.gapSection20_3MguY {\n    height: 500px;\n}\n\n.signatureSize_sxvNt img {\n    max-width: 200px;\n    max-height: 80px;\n}\n\n.centerText_18Ucs {\n    text-align: center;\n}\n\n.descriptionText_3Sm2X {\n    font-size: 15px !important;\n}\n\n.tableSize_1-JxU {\n    width: 100%;\n}\n\n.tableSize_1-JxU td {\n    border: none !important;\n}\n\n.cellWidth_bwvrA {\n    width: 60%;\n}\n\n.textBorder_1kup5 {\n    border: solid 1px;\n}\n\n.textContainer_3DR5l {\n    padding: 10px 0px 100px;\n}\n\n.signatureBox_ICRdy {\n    width: 50%;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_1hJ5I",
	"conditionsContainer": "conditionsContainer_3vMmb",
	"gapSection20": "gapSection20_3MguY",
	"signatureSize": "signatureSize_sxvNt",
	"centerText": "centerText_18Ucs",
	"descriptionText": "descriptionText_3Sm2X",
	"tableSize": "tableSize_1-JxU",
	"cellWidth": "cellWidth_bwvrA",
	"textBorder": "textBorder_1kup5",
	"textContainer": "textContainer_3DR5l",
	"signatureBox": "signatureBox_ICRdy"
};