exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_14XYa {\n    padding: 0 80px;\n}\n\n.infoContainer_14XYa > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.referalContainer_2PSAR {\n    padding-top: 30px;\n}\n\n.sectionSubList_38nRo {\n    list-style: none;\n    position: relative;\n}\n\n.sectionListChild_21Qn_ {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.sectionListChild_21Qn_ input {\n    position: absolute;\n    left: 10px;\n    line-height: 20px;\n}\n\n.submitContent_3fVdy {\n    padding-top: 10px;\n    font-weight: 800;\n    font-style: italic;\n}\n\n.gapSection17_19tLN {\n    height: 20px;\n}\n\n.signatureSize_1Elq0 img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_14XYa",
	"referalContainer": "referalContainer_2PSAR",
	"sectionSubList": "sectionSubList_38nRo",
	"sectionListChild": "sectionListChild_21Qn_",
	"submitContent": "submitContent_3fVdy",
	"gapSection17": "gapSection17_19tLN",
	"signatureSize": "signatureSize_1Elq0"
};