exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".referalContent_1MFNM {\n    padding: 0 20px 20px 20px;\n}\n", ""]);

// exports
exports.locals = {
	"referalContent": "referalContent_1MFNM"
};