exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".header_1SZnB {\n    padding: 0 0 10px 0;\n    width: 100%;\n}\n\n.sectionHeader_1X2TZ {\n    display: inline-block;\n    width: 100%;\n}\n\n.sectionHeader_1X2TZ img {\n    height: 60px;\n}\n", ""]);

// exports
exports.locals = {
	"header": "header_1SZnB",
	"sectionHeader": "sectionHeader_1X2TZ"
};