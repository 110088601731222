exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".imgWidth_1UWjv {\n    width: 250px;\n}\n\n.textCenter_hY9d1 {\n    text-align: center;\n}\n\n.paddingTopLogo_3-MW- {\n    padding-top: 40px;\n}\n\n.containerPosition_3gNoD {\n    position: relative;\n    width: 100%;\n    font-size: 16px;\n}\n\n.paddingTopContent_3AnK5 {\n    padding-top: 20px;\n}\n\n.textLeft_2hwLb {\n    text-align: left;\n}\n\n.verticalCenter_3GOtv {\n    vertical-align: middle !important;\n}\n\n.checkBoxDisplay_3lRl1 {\n    display: inline;\n    padding-right: 30px;\n}\n\n.paddingTable_1beeJ td {\n    padding: 8px 8px 0px 8px !important;\n}\n\n.inlineInput_3iLHh {\n    display: inline-block;\n    width: 30%;\n    margin: 0px;\n    vertical-align: bottom;\n}\n\n.paddingBottom_e9aLp {\n    padding-bottom: 30px;\n}\n\n.marginNone_3fCSV {\n    margin: 0px;\n}\n\n.inputDisplay_22EnE {\n    display: none;\n}\n\n.pageSize_2wlIH {\n    position: relative;\n    min-height: 1420px;\n}\n\n.paddingNoneTable_359vK td {\n    padding: 0px !important;\n    vertical-align: middle !important;\n}\n\n.smallPaddingTable_2Qrex td {\n    padding: 2px !important;\n    vertical-align: middle !important;\n}\n\n.breakPage_iD7N4 {\n    page-break-after: always;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .paddingTopLogo_3-MW- {\n        padding-top: 10pt;\n    }\n\n    .imgWidth_1UWjv {\n        width: 150px;\n    }\n\n    .pageSize_2wlIH {\n        min-height: 27.9cm;\n    }\n\n    .containerPosition_3gNoD {\n        font-size: 7pt;\n    }\n\n    .paddingTopContent_3AnK5 {\n        padding-top: 10pt;\n    }\n\n    .paddingTable_1beeJ td,\n    .paddingNoneTable_359vK td {\n        padding: 0px !important;\n    }\n\n    .inputHeight_1b8IO {\n        height: 24px;\n    }\n\n    .displayNone_1Xrol {\n        display: none;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"imgWidth": "imgWidth_1UWjv",
	"textCenter": "textCenter_hY9d1",
	"paddingTopLogo": "paddingTopLogo_3-MW-",
	"containerPosition": "containerPosition_3gNoD",
	"paddingTopContent": "paddingTopContent_3AnK5",
	"textLeft": "textLeft_2hwLb",
	"verticalCenter": "verticalCenter_3GOtv",
	"checkBoxDisplay": "checkBoxDisplay_3lRl1",
	"paddingTable": "paddingTable_1beeJ",
	"inlineInput": "inlineInput_3iLHh",
	"paddingBottom": "paddingBottom_e9aLp",
	"marginNone": "marginNone_3fCSV",
	"inputDisplay": "inputDisplay_22EnE",
	"pageSize": "pageSize_2wlIH",
	"paddingNoneTable": "paddingNoneTable_359vK",
	"smallPaddingTable": "smallPaddingTable_2Qrex",
	"breakPage": "breakPage_iD7N4",
	"inputHeight": "inputHeight_1b8IO",
	"displayNone": "displayNone_1Xrol"
};