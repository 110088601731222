exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".footerWrap_106ah {\n    font-size: 16px;\n}\n\n.footerWrap_106ah p {\n    margin: 0;\n}\n\n.footerDescription_3FD7J {\n    font-style: italic;\n    margin-right: 50px;\n}\n\n.footerLeft_1zRQF {\n    width: 75%;\n    display: inline-block;\n    font-size: 12px;\n}\n\n.footerRight_3EfBW {\n    width: 25%;\n    display: inline-block;\n    text-align: right;\n    font-size: 12px;\n}\n\n.footerBold_1gAtD {\n    font-weight: bold;\n    padding-left: 3px;\n    font-size: 13px;\n}\n", ""]);

// exports
exports.locals = {
	"footerWrap": "footerWrap_106ah",
	"footerDescription": "footerDescription_3FD7J",
	"footerLeft": "footerLeft_1zRQF",
	"footerRight": "footerRight_3EfBW",
	"footerBold": "footerBold_1gAtD"
};