exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".rowCondition_3Z2LK {\n    padding: 20px 0;\n}\n\n.dateWrap_et0qy {\n    display: inline-block;\n    padding: 0 10px;\n}\n\n.titleConditionItem_3kWEB {\n    font-weight: 800;\n}\n\n.initialBoxImage_3lzPf img {\n    max-width: 100px;\n    max-height: 34px;\n    height: auto;\n    width: auto;\n}\n\n.conditionItemLeft_1obxB {\n    width: 13%;\n}\n\n.conditionItemRight_4n4Ux {\n    width: 87%;\n}\n\n.rowCondition_3Z2LK span {\n    font-size: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"rowCondition": "rowCondition_3Z2LK",
	"dateWrap": "dateWrap_et0qy",
	"titleConditionItem": "titleConditionItem_3kWEB",
	"initialBoxImage": "initialBoxImage_3lzPf",
	"conditionItemLeft": "conditionItemLeft_1obxB",
	"conditionItemRight": "conditionItemRight_4n4Ux"
};