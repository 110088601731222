exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".containerPosition_1teZ- {\n    position: relative;\n    width: 100%;\n    font-size: 16px;\n}\n\n.pageSize_2X45r {\n    position: relative;\n    min-height: 1420px;\n}\n\n.paddingSides_39i1x {\n    padding: 30px 30px 0px;\n}\n\n.imgWidth_5ihTm {\n    width: 300px;\n}\n\n.textCenter_1IqeJ {\n    text-align: center;\n}\n\n.paddingBottom_kt8QJ {\n    padding-bottom: 20px;\n}\n\n.inlineInput_2NUON {\n    display: inline-block;\n}\n\n.signatureInput_1-3Ai {\n    vertical-align: bottom;\n    margin: 0px !important;\n    width: 30%;\n}\n\n.inputWidth_2i5S9 {\n    width: 40%;\n}\n\n.selectProgram_14iN2 {\n    position: absolute;\n    top: 0px;\n    left: 665px;\n    width: 350px;\n    display: inline-block;\n}\n\n.breakPage_jBOi4 {\n    page-break-after: always;\n}\n\n.initialsBoxSize_wUIQp img {\n    max-width: 100px;\n    max-height: 34px;\n    width: auto;\n    height: auto;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_1teZ- {\n        font-size: 10pt;\n    }\n\n    .displayNone_31ES6 {\n        display: none;\n    }\n\n    .pageSize_2X45r {\n        min-height: 27.9cm;\n    }\n\n    .footerPosition_1UVuY {\n        bottom: 20pt;\n    }\n}", ""]);

// exports
exports.locals = {
	"containerPosition": "containerPosition_1teZ-",
	"pageSize": "pageSize_2X45r",
	"paddingSides": "paddingSides_39i1x",
	"imgWidth": "imgWidth_5ihTm",
	"textCenter": "textCenter_1IqeJ",
	"paddingBottom": "paddingBottom_kt8QJ",
	"inlineInput": "inlineInput_2NUON",
	"signatureInput": "signatureInput_1-3Ai",
	"inputWidth": "inputWidth_2i5S9",
	"selectProgram": "selectProgram_14iN2",
	"breakPage": "breakPage_jBOi4",
	"initialsBoxSize": "initialsBoxSize_wUIQp",
	"displayNone": "displayNone_31ES6",
	"footerPosition": "footerPosition_1UVuY"
};