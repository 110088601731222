exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_2dzOH {\n    padding: 0 80px;\n}\n\n.infoContainer_2dzOH > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.infoContainer_2dzOH > h4 {\n    text-align: center;\n    font-weight: 800;\n    text-decoration: underline;\n    font-style: italic;\n}\n\n.descriptionConent_1A1VL {\n    letter-spacing: 0.4px;\n    line-height: 25px;\n}\n\n.descriptionConent_1A1VL > ol > li {\n    padding-bottom: 10px;\n}\n.sectionPoints_3klOH {\n    padding: 40px 10px;\n    font-size: 18px;\n    letter-spacing: 0.4px;\n    line-height: 30px;\n}\n\n.sectionPoints_3klOH > div > ul > li {\n    padding-bottom: 30px;\n}\n\n.gapSection16_2w0EJ {\n    height: 450px;\n}\n\n.signatureSize_3Ngw9 img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_2dzOH",
	"descriptionConent": "descriptionConent_1A1VL",
	"sectionPoints": "sectionPoints_3klOH",
	"gapSection16": "gapSection16_2w0EJ",
	"signatureSize": "signatureSize_3Ngw9"
};