exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".containerPosition_2_tFk {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n.pageSize_3_0M6 {\n    position: relative;\n    min-height: 1420px;\n}\n\n.imgWidth_3U7GO {\n    width: 300px;\n}\n\n.smallImgWidth_1n2T9 {\n    width: 200px;\n}\n\n.textCenter_2mRNy {\n    text-align: center;\n}\n\n.paddingSides_1Jqp_ {\n    padding: 30px 30px 0px;\n}\n\n.paddingBottom_JH1hL {\n    padding-bottom: 20px;\n}\n\n.tableMargin_gYf0L td {\n    border: 0px !important;\n}\n\n.bottomAlign_3aWnl {\n    vertical-align: bottom !important;\n}\n\n.marginNone_1oOvo {\n    margin: 0px !important;\n}\n\n.inlineInput_IegGR {\n    display: inline-block;\n}\n\n.signatureInput_iRLTT {\n    vertical-align: bottom;\n    margin: 0px !important;\n    width: 30%;\n}\n\n.inputWidth_3eevC {\n    width: 40%;\n}\n\n.leftMargin_2uJdO {\n    margin-left: 30px;\n}\n\n.initialsBoxSize_NhB6Z img {\n    max-width: 100px;\n    max-height: 34px;\n    width: auto;\n    height: auto;\n}\n\n.breakPage_1TDpM {\n    page-break-after: always;\n}\n\n.bottomAlign_3aWnl {\n    vertical-align: bottom;\n}\n\n.inputDislplay_2hg0Q {\n    display: none;\n}\n\n.initialSize_2JPEY img {\n    max-width: 100px;\n    max-height: 34px;\n    width: auto;\n    height: auto;\n}\n\n.textRight_zCDSn {\n    text-align: right;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_2_tFk {\n        font-size: 10pt;\n    }\n\n    .displayNone_3YDrb {\n        display: none;\n    }\n\n    .pageSize_3_0M6 {\n        min-height: 27.9cm;\n    }\n\n    .footerPosition_6UJ3n {\n        bottom: 20pt;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"containerPosition": "containerPosition_2_tFk",
	"pageSize": "pageSize_3_0M6",
	"imgWidth": "imgWidth_3U7GO",
	"smallImgWidth": "smallImgWidth_1n2T9",
	"textCenter": "textCenter_2mRNy",
	"paddingSides": "paddingSides_1Jqp_",
	"paddingBottom": "paddingBottom_JH1hL",
	"tableMargin": "tableMargin_gYf0L",
	"bottomAlign": "bottomAlign_3aWnl",
	"marginNone": "marginNone_1oOvo",
	"inlineInput": "inlineInput_IegGR",
	"signatureInput": "signatureInput_iRLTT",
	"inputWidth": "inputWidth_3eevC",
	"leftMargin": "leftMargin_2uJdO",
	"initialsBoxSize": "initialsBoxSize_NhB6Z",
	"breakPage": "breakPage_1TDpM",
	"inputDislplay": "inputDislplay_2hg0Q",
	"initialSize": "initialSize_2JPEY",
	"textRight": "textRight_zCDSn",
	"displayNone": "displayNone_3YDrb",
	"footerPosition": "footerPosition_6UJ3n"
};