exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".titleConditions_268Wp {\n    font-weight: 800;\n    text-align: center;\n    text-decoration: underline;\n    font-size: 20px;\n}\n", ""]);

// exports
exports.locals = {
	"titleConditions": "titleConditions_268Wp"
};