exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".imgWidth_1gXCm {\n    width: 250px;\n}\n\n.textCenter_K1Xgi {\n    text-align: center;\n}\n\n.paddingTopLogo_2vqi9 {\n    padding-top: 40px;\n}\n\n.paddingContent_3SD7X {\n    padding: 20px 0px;\n}\n\n.headTable_31Pk6 {\n    background-color: #1e487c;\n    color: #fff;\n}\n\n.textCenterTable_2MNZV th,\n.textCenterTable_2MNZV td {\n    text-align: center;\n    vertical-align: middle !important;\n}\n\n.rowTable_m4zbU {\n    background-color: #b8cce4;\n}\n\n.containerPosition_ib3t_ {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n.pageSize_3_Bst {\n    position: relative;\n    min-height: 1420px;\n}\n\n.signatureWrap_xBv1Y {\n    display: inline-block;\n    width: 33%;\n    padding-right: 30px;\n    margin: 0px;\n    vertical-align: bottom;\n}\n\n.breakPage_OD1tM {\n    page-break-after: always;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_ib3t_ {\n        font-size: 7pt;\n    }\n\n    .pageSize_3_Bst {\n        min-height: 27.9cm;\n    }\n\n    .paddingTopLogo_2vqi9 {\n        padding-top: 10pt;\n    }\n\n    .imgWidth_1gXCm {\n        width: 200pt;\n    }\n\n    .displayNone_3xALZ {\n        display: none;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"imgWidth": "imgWidth_1gXCm",
	"textCenter": "textCenter_K1Xgi",
	"paddingTopLogo": "paddingTopLogo_2vqi9",
	"paddingContent": "paddingContent_3SD7X",
	"headTable": "headTable_31Pk6",
	"textCenterTable": "textCenterTable_2MNZV",
	"rowTable": "rowTable_m4zbU",
	"containerPosition": "containerPosition_ib3t_",
	"pageSize": "pageSize_3_Bst",
	"signatureWrap": "signatureWrap_xBv1Y",
	"breakPage": "breakPage_OD1tM",
	"displayNone": "displayNone_3xALZ"
};