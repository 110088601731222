exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".headerTitle_2L8bn {\n    font-size: 25px;\n    text-align: center;\n    margin-bottom: 0;\n    position: relative;\n    margin-top: 40px;\n}\n\n.desc1_1Fpgi {\n    font-size: 18px;\n    margin-bottom: 0;\n    padding: 20px 0;\n}\n\n.rowItemTitle_166TK {\n    font-size: 14px;\n    font-weight: bold;\n    text-align: center;\n    background: #ccc;\n    border-top: 1px solid;\n    margin: 20px 0;\n}\n.rowItemWrap_2ifqQ {\n    margin-bottom: 5px;\n}\n\n.gapSection4_3n9NE {\n    height: 1150px;\n}\n\n.marginNone_6vaP3 {\n    margin: 0px;\n}\n\n.signatureTitle_3yDLl{\n    text-align: center;\n    padding-bottom: 10px;\n    font-style: oblique;\n}", ""]);

// exports
exports.locals = {
	"headerTitle": "headerTitle_2L8bn",
	"desc1": "desc1_1Fpgi",
	"rowItemTitle": "rowItemTitle_166TK",
	"rowItemWrap": "rowItemWrap_2ifqQ",
	"gapSection4": "gapSection4_3n9NE",
	"marginNone": "marginNone_6vaP3",
	"signatureTitle": "signatureTitle_3yDLl"
};