exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_1TPAH {\n    padding: 0 80px;\n}\n\n.divition_23lId {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.infoContainer_1TPAH > p {\n    text-align: center;\n    font-weight: 800;\n    font-style: italic;\n}\n\n.infoContainer_1TPAH > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.noPaddingBottom_3gdMa {\n    padding-bottom: 0 !important;\n}\n\n.noPaddingBottom_3gdMa > [class*=\"rowCondition\"] {\n    padding-top: 0;\n}\n\n.titleBold_2ODoE {\n    font-weight: 800;\n}\n\n.gapSection12_O6w1o {\n    height: 20px;\n}\n\n.alignment_2095T {\n    text-align: center;\n}\n\n.signatureSize_1nFbi img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_1TPAH",
	"divition": "divition_23lId",
	"noPaddingBottom": "noPaddingBottom_3gdMa",
	"titleBold": "titleBold_2ODoE",
	"gapSection12": "gapSection12_O6w1o",
	"alignment": "alignment_2095T",
	"signatureSize": "signatureSize_1nFbi"
};