exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".button_j4DDO {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 0;\n    padding: 3px;\n    border: none;\n    height: 32px;\n    min-width: 76px;\n    width: 100%;\n    background-color: #ff6a47;\n    font-family: \"Pathway Gothic One\", sans-serif;\n    font-size: 20px;\n    font-weight: 300;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: normal;\n    letter-spacing: normal;\n    text-align: center;\n    color: #ffffff;\n    outline: none;\n}\n\nbutton.button_j4DDO {\n    outline: none;\n}\n\n.button_j4DDO:active {\n    background-color: #df593b;\n}\n\n.button_j4DDO:disabled {\n    opacity: 0.4;\n    cursor: auto;\n}\n\n.button_j4DDO:focus {\n    box-shadow: 0 0 0 2px #1e90ff;\n}\n", ""]);

// exports
exports.locals = {
	"button": "button_j4DDO"
};