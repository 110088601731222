exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer10_2-PKx {\n    padding: 0 40px;\n}\n\n.infoContainer10_2-PKx > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.infoContainer10_2-PKx > div {\n    margin-bottom: 0;\n}\n\n.infoContainer10_2-PKx select {\n    font-size: 14px;\n}\n\n.infoContainer10_2-PKx div > input,\n.infoContainer10_2-PKx div > label {\n    font-size: 14px;\n    padding: 3px 14px;\n    height: 24px;\n}\n\n.sectionSubList_4SJVw {\n    list-style: none;\n    position: relative;\n    padding-left: 27px;\n}\n\n.sectionListChild_3ozsn {\n    margin-top: 5px;\n    margin-bottom: 5px;\n}\n\n.sectionListChild_3ozsn input {\n    position: absolute;\n    left: 10px;\n    line-height: 20px;\n}\n\n.compliance_1xWk3 {\n    padding-top: 10px;\n    font-size: 14px !important;\n}\n\n.subtitle_1Oll8 {\n    font-size: 14px;\n    margin: 0;\n    background-color: #040404;\n    color: white;\n    text-align: center;\n    padding-top: 5px;\n    padding-bottom: 5px;\n}\n\n.divition_2O5F6 {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.ferpaInfo_Osc65 {\n    font-style: italic;\n    font-size: 13px;\n    font-weight: 800;\n}\n\n.understand_1EQT8 {\n    padding-top: 14px;\n    letter-spacing: 0.4px;\n    font-size: 14px !important;\n}\n\n.concentContainer_21WSx {\n    border: 1px solid #040404;\n}\n\n.initialBox_3XeTx {\n    width: 100px;\n    height: 60px;\n}\n\n.initialBox_3XeTx img {\n    max-width: 150px;\n    max-height: 60px;\n    height: auto;\n    width: auto;\n}\n\n.initialBox_3XeTx button {\n    width: 40px;\n    height: 25px;\n    top: 17px;\n    left: 28px;\n}\n\n.signatureLeft_Xhv_- {\n    margin: 0 !important;\n}\n\n.dateRight_x0hpJ {\n    margin: 0 0 0 61px !important;\n}\n\n.centered_KeQ_G {\n    text-align: center;\n}\n\n.signatureSize_1XSlY img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer10": "infoContainer10_2-PKx",
	"sectionSubList": "sectionSubList_4SJVw",
	"sectionListChild": "sectionListChild_3ozsn",
	"compliance": "compliance_1xWk3",
	"subtitle": "subtitle_1Oll8",
	"divition": "divition_2O5F6",
	"ferpaInfo": "ferpaInfo_Osc65",
	"understand": "understand_1EQT8",
	"concentContainer": "concentContainer_21WSx",
	"initialBox": "initialBox_3XeTx",
	"signatureLeft": "signatureLeft_Xhv_-",
	"dateRight": "dateRight_x0hpJ",
	"centered": "centered_KeQ_G",
	"signatureSize": "signatureSize_1XSlY"
};