exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_ndbxO {\n    padding: 0 80px;\n}\n\n.infoContainer_ndbxO > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.sectionSubList_3PVNR {\n    list-style: none;\n    position: relative;\n}\n\n.sectionListChild_2cgSL {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.sectionListChild_2cgSL input {\n    position: absolute;\n    left: 10px;\n    line-height: 20px;\n}\n\n.descriptionConent_U8Orh {\n    letter-spacing: 0.4px;\n    font-size: 16px;\n    line-height: 30px;\n}\n\n.divition_2RtAR {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.gapSection13_3inr3 {\n    height: 580px;\n}\n\n.alignment_1VlAM {\n    padding-top: 30px;\n}\n\n.signatureLeft_35hEX {\n    margin: 0 !important;\n}\n\n.datePickerRight_2q8Yu {\n    margin: 0 0 0 78px !important;\n}\n\n.signatureSize_Dot10 img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_ndbxO",
	"sectionSubList": "sectionSubList_3PVNR",
	"sectionListChild": "sectionListChild_2cgSL",
	"descriptionConent": "descriptionConent_U8Orh",
	"divition": "divition_2RtAR",
	"gapSection13": "gapSection13_3inr3",
	"alignment": "alignment_1VlAM",
	"signatureLeft": "signatureLeft_35hEX",
	"datePickerRight": "datePickerRight_2q8Yu",
	"signatureSize": "signatureSize_Dot10"
};