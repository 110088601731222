exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_7e3Gr {\n    padding: 0 80px;\n}\n\n.divition_3G7C6 {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.infoContainer_7e3Gr > p {\n    text-align: center;\n    font-weight: 800;\n    font-style: italic;\n}\n\n.infoContainer_7e3Gr > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_7e3Gr",
	"divition": "divition_3G7C6"
};