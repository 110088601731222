exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".titleCurve_2XWIu {\n    text-decoration: underline;\n    font-weight: 800;\n}\n\n.descriptionText_UCPNs {\n    font-size: 15px !important;\n}", ""]);

// exports
exports.locals = {
	"titleCurve": "titleCurve_2XWIu",
	"descriptionText": "descriptionText_UCPNs"
};