exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".agreementContainer_yzQbj {\n    padding-top: 25px;\n    font-size: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"agreementContainer": "agreementContainer_yzQbj"
};