exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".containerPosition_3cogC {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n.pageSize_15EpL {\n    position: relative;\n    min-height: 1420px;\n}\n\n.paddingToplogo_35ExT {\n    padding-top: 40px;\n}\n\n.imgWidth_3cmoP {\n    width: 250px;\n}\n\n.inlineInput_105b8 {\n    display: inline-block;\n}\n\n.nameInput_38_wm {\n    width: 350px;\n}\n\n.programInput_2hTZH {\n    width: 47%;\n}\n\n.paddingTopContent_itzj4 {\n    padding-top: 50px;\n}\n\n.signatureWidth_Ym7DX {\n    width: 285px;\n    margin: 0px 10px;\n    vertical-align: bottom;\n}\n\n.textCenter_a05Bg {\n    text-align: center;\n}\n\n.paddingTopInternalUse_1BsYP {\n    padding-top: 120px;\n}\n\n.smallpaddingTop_4Rvyx {\n    padding-top: 20px;\n}\n\n.paddingSides_jKVag {\n    padding-left: 5px;\n    padding-right: 5px;\n}\n\n.signatureHeight_DYIu4 {\n    height: 80px;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_3cogC {\n        font-size: 7pt;\n    }\n\n    .displayNone_DYLxF {\n        display: none;\n    }\n\n    .pageSize_15EpL {\n        min-height: 27.9cm;\n        padding: 0pt 20pt;\n    }\n\n    .paddingTopContent_itzj4 {\n        padding-top: 20pt;\n    }\n\n    .signatureWidth_Ym7DX {\n        width: 150pt;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"containerPosition": "containerPosition_3cogC",
	"pageSize": "pageSize_15EpL",
	"paddingToplogo": "paddingToplogo_35ExT",
	"imgWidth": "imgWidth_3cmoP",
	"inlineInput": "inlineInput_105b8",
	"nameInput": "nameInput_38_wm",
	"programInput": "programInput_2hTZH",
	"paddingTopContent": "paddingTopContent_itzj4",
	"signatureWidth": "signatureWidth_Ym7DX",
	"textCenter": "textCenter_a05Bg",
	"paddingTopInternalUse": "paddingTopInternalUse_1BsYP",
	"smallpaddingTop": "smallpaddingTop_4Rvyx",
	"paddingSides": "paddingSides_jKVag",
	"signatureHeight": "signatureHeight_DYIu4",
	"displayNone": "displayNone_DYLxF"
};