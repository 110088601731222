exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer10_2B4Y- {\n    padding: 0 40px;\n}\n\n.infoContainer10_2B4Y- > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.infoContainer10_2B4Y- > div {\n    margin-bottom: 0;\n}\n\n.infoContainer10_2B4Y- select {\n    font-size: 14px;\n}\n\n.infoContainer10_2B4Y- div > input,\n.infoContainer10_2B4Y- div > label {\n    font-size: 14px;\n    padding: 3px 14px;\n    height: 24px;\n}\n\n.sectionSubList_28TLJ {\n    list-style: none;\n    position: relative;\n    padding-left: 27px;\n}\n\n.sectionListChild_g5aDR {\n    margin-top: 5px;\n    margin-bottom: 5px;\n}\n\n.sectionListChild_g5aDR input {\n    position: absolute;\n    left: 10px;\n    line-height: 20px;\n}\n\n.compliance_OQ2Wm {\n    padding-top: 10px;\n    font-size: 14px !important;\n}\n\n.subtitle_1t6tL {\n    font-size: 14px;\n    margin: 0;\n    background-color: #040404;\n    color: white;\n    text-align: center;\n    padding-top: 5px;\n    padding-bottom: 5px;\n}\n\n.divition_3kB7G {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.ferpaInfo_2NpSk {\n    font-style: italic;\n    font-size: 13px;\n    font-weight: 800;\n}\n\n.understand_3kEtz {\n    padding-top: 14px;\n    letter-spacing: 0.4px;\n    font-size: 14px !important;\n}\n\n.concentContainer_1bAPY {\n    border: 1px solid #040404;\n}\n\n.initialBox_vjB49 {\n    width: 100px;\n    height: 60px;\n}\n\n.initialBox_vjB49 img {\n    max-width: 150px;\n    max-height: 60px;\n    height: auto;\n    width: auto;\n}\n\n.initialBox_vjB49 button {\n    width: 40px;\n    height: 25px;\n    top: 17px;\n    left: 28px;\n}\n\n.signatureLeft_1i0z2 {\n    margin: 0 !important;\n}\n\n.dateRight_j1ieU {\n    margin: 0 0 0 61px !important;\n}\n\n.centered_1jHtQ {\n    text-align: center;\n}\n\n.signatureSize_o4yDl img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer10": "infoContainer10_2B4Y-",
	"sectionSubList": "sectionSubList_28TLJ",
	"sectionListChild": "sectionListChild_g5aDR",
	"compliance": "compliance_OQ2Wm",
	"subtitle": "subtitle_1t6tL",
	"divition": "divition_3kB7G",
	"ferpaInfo": "ferpaInfo_2NpSk",
	"understand": "understand_3kEtz",
	"concentContainer": "concentContainer_1bAPY",
	"initialBox": "initialBox_vjB49",
	"signatureLeft": "signatureLeft_1i0z2",
	"dateRight": "dateRight_j1ieU",
	"centered": "centered_1jHtQ",
	"signatureSize": "signatureSize_o4yDl"
};