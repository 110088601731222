exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_2rKDK {\n    padding: 0 80px;\n}\n\n.infoContainer_2rKDK > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.infoContainer_2rKDK > h4 {\n    text-align: center;\n    font-weight: 800;\n    text-decoration: underline;\n    font-style: italic;\n}\n\n.descriptionConent_1EAwL {\n    letter-spacing: 0.4px;\n    line-height: 25px;\n}\n\n.descriptionConent_1EAwL > ol > li {\n    padding-bottom: 10px;\n}\n.sectionPoints_2nbPO {\n    padding: 40px 10px;\n    font-size: 18px;\n    letter-spacing: 0.4px;\n    line-height: 30px;\n}\n\n.sectionPoints_2nbPO > div > ul > li {\n    padding-bottom: 30px;\n}\n\n.gapSection16_3BKP_ {\n    height: 450px;\n}\n\n.signatureSize_3akab img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_2rKDK",
	"descriptionConent": "descriptionConent_1EAwL",
	"sectionPoints": "sectionPoints_2nbPO",
	"gapSection16": "gapSection16_3BKP_",
	"signatureSize": "signatureSize_3akab"
};