exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_2sQmT {\n    padding: 0 20px;\n}\n\n.divition_3qBsC {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.infoContainer_2sQmT > p {\n    text-align: center;\n    font-style: italic;\n}\n\n.infoContainer_2sQmT > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.tableContainer_-FiLH {\n    padding: 20px 0 0 0;\n}\n\n.advertisement_1lkOo {\n    text-align: center;\n    font-style: italic;\n    font-weight: 800;\n}\n\n.gapSection11_3OCy2 {\n    height: 380px;\n}\n", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_2sQmT",
	"divition": "divition_3qBsC",
	"tableContainer": "tableContainer_-FiLH",
	"advertisement": "advertisement_1lkOo",
	"gapSection11": "gapSection11_3OCy2"
};