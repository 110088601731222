exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_nzzzz {\n    padding: 0 80px;\n}\n\n.divition_3roRH {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.infoContainer_nzzzz > div > p {\n    text-align: center;\n    font-weight: 800;\n    font-style: italic;\n}\n\n.infoContainer_nzzzz > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.checkBoxContent_3Fj6C {\n    padding-left: 10px;\n}\n\n.creditContainer_395l9 {\n    padding: 15px 0 0 0;\n}\n.creditContainer_395l9 > h4 {\n    display: inline;\n    font-size: 17.5px;\n    font-weight: 800;\n}\n\n.creditContainer_395l9 > span {\n    font-style: italic;\n    font-size: 13px;\n}\n\n.continueDetail_1KSmb {\n    font-style: italic;\n    font-size: 13px;\n}\n", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_nzzzz",
	"divition": "divition_3roRH",
	"checkBoxContent": "checkBoxContent_3Fj6C",
	"creditContainer": "creditContainer_395l9",
	"continueDetail": "continueDetail_1KSmb"
};