exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".headerTitle_3pXdI {\n    font-size: 25px;\n    text-align: center;\n    margin-bottom: 0;\n}\n.rowItemTitle_2O4Rd {\n    font-size: 14px;\n    font-weight: bold;\n    text-align: center;\n    background: #ccc;\n    border-top: 1px solid;\n    margin: 20px 0;\n}\n.rowItemWrap_kviCV {\n    margin-bottom: 5px;\n}\n\n.pointInputWrap_3EO1v {\n    display: inline-block;\n}\n\n.tableWithdraw_Nw8Hz {\n    width: 100%;\n    text-align: center;\n    border: 1px solid #d6d6d6;\n}\n.tableWithdraw_Nw8Hz th p {\n    text-align: center;\n    margin: 0;\n}\n\n.tableWithdraw_Nw8Hz td {\n    width: 50%;\n}\n\n.noManhatan_3iEuV {\n    height: 181px;\n}\n\n.gapSection2_3QovP {\n    height: 1300px;\n}\n.headerWrap_9X-pK {\n    position: relative;\n    margin-top: 40px;\n}", ""]);

// exports
exports.locals = {
	"headerTitle": "headerTitle_3pXdI",
	"rowItemTitle": "rowItemTitle_2O4Rd",
	"rowItemWrap": "rowItemWrap_kviCV",
	"pointInputWrap": "pointInputWrap_3EO1v",
	"tableWithdraw": "tableWithdraw_Nw8Hz",
	"noManhatan": "noManhatan_3iEuV",
	"gapSection2": "gapSection2_3QovP",
	"headerWrap": "headerWrap_9X-pK"
};