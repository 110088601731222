exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".datePicker_TNuXX > div {\n  width: 100%;\n}\n\n.formContainer_1wfJC {\n  background-color: #fff;\n  min-height: 100vh;\n}\n\n.imageWidth_2cqqY {\n  width: 200px;\n}\n\n.lineColor_t8EC0 {\n  border-top: 3px solid #337ab7;\n}\n\n.paddingForm_1tAQV {\n  padding: 10px 100px;\n}\n\n.formPadding_XrvzP {\n  padding: 20px 10px;\n}\n\n.colorRed_2qxac {\n  color: #ff0000;\n}\n\n.customRates_3iDV1 {\n  padding-right: 20px;\n}", ""]);

// exports
exports.locals = {
	"datePicker": "datePicker_TNuXX",
	"formContainer": "formContainer_1wfJC",
	"imageWidth": "imageWidth_2cqqY",
	"lineColor": "lineColor_t8EC0",
	"paddingForm": "paddingForm_1tAQV",
	"formPadding": "formPadding_XrvzP",
	"colorRed": "colorRed_2qxac",
	"customRates": "customRates_3iDV1"
};