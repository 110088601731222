exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".containerPosition_3Q0e_ {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n.pageSize_UhZZx {\n    position: relative;\n    min-height: 1420px;\n    padding: 30px 50px;\n}\n\n.inlineInput_2d3BN {\n    display: inline-block;\n}\n\n.inputWidth_27FN2 {\n    width: 50%;\n}\n\n.paddingRight_2ugl6 {\n    padding-right: 40px;\n}\n\n.textCenter_3juE1 {\n    text-align: center;\n}\n\n.imgWidth_jgOLI {\n    width: 250px;\n}\n\n.paddingToplogo_J247f {\n    padding-top: 40px;\n}\n\n.signatureMargin_1m8bR {\n    margin: 0px;\n    vertical-align: bottom;\n}\n\n.footerPosition_3tKMR {\n    position: absolute;\n    width: 100%;\n    bottom: 40px;\n}\n\n.breakPage_84nnM {\n    page-break-after: always;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_3Q0e_ {\n        font-size: 10pt;\n    }\n\n    .displayNone_38T5u {\n        display: none;\n    }\n\n    .pageSize_UhZZx {\n        min-height: 27.9cm;\n    }\n\n    .footerPosition_3tKMR {\n        bottom: 20pt;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"containerPosition": "containerPosition_3Q0e_",
	"pageSize": "pageSize_UhZZx",
	"inlineInput": "inlineInput_2d3BN",
	"inputWidth": "inputWidth_27FN2",
	"paddingRight": "paddingRight_2ugl6",
	"textCenter": "textCenter_3juE1",
	"imgWidth": "imgWidth_jgOLI",
	"paddingToplogo": "paddingToplogo_J247f",
	"signatureMargin": "signatureMargin_1m8bR",
	"footerPosition": "footerPosition_3tKMR",
	"breakPage": "breakPage_84nnM",
	"displayNone": "displayNone_38T5u"
};