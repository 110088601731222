exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_2CVo4 {\n    padding: 0 80px;\n}\n\n.infoContainer_2CVo4 > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.sectionSubList_1E40V {\n    list-style: none;\n    position: relative;\n}\n\n.sectionListChild_1aA6A {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.sectionListChild_1aA6A input {\n    position: absolute;\n    left: 10px;\n    line-height: 20px;\n}\n\n.descriptionConent_2-QnX {\n    letter-spacing: 0.4px;\n    line-height: 25px;\n}\n\n.descriptionConent_2-QnX > ol > li {\n    padding-bottom: 10px;\n}\n\n.divition_iYJ3P {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.allWidth_2EoMB {\n    width: 100% !important;\n}\n\n.initialBox_DjwJC {\n    width: 150px;\n    height: 60px;\n}\n\n.initialBox_DjwJC img {\n    max-width: 150px;\n    max-height: 60px;\n    height: auto;\n    width: auto;\n}\n\n.initialBox_DjwJC button {\n    width: 60px;\n    height: 30px;\n    top: 16px;\n    left: 44px;\n}\n\n.blockInput_1LV9B {\n    display: block !important;\n}\n\n.signatureSize_1SHV1 img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_2CVo4",
	"sectionSubList": "sectionSubList_1E40V",
	"sectionListChild": "sectionListChild_1aA6A",
	"descriptionConent": "descriptionConent_2-QnX",
	"divition": "divition_iYJ3P",
	"allWidth": "allWidth_2EoMB",
	"initialBox": "initialBox_DjwJC",
	"blockInput": "blockInput_1LV9B",
	"signatureSize": "signatureSize_1SHV1"
};