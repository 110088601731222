exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".agreementFormWrap_2-gVe {\n    position: relative;\n    width: 100%;\n}\n\n.listCampus_1w0fe {\n    position: absolute;\n    top: 30px;\n    right: 0;\n    z-index: 3;\n}\n\n.campusItem_21TGm {\n    display: inline-block;\n    margin-right: 10px;\n}\n\n.campusLabel_3ZO1x {\n    margin-left: 3px;\n}\n", ""]);

// exports
exports.locals = {
	"agreementFormWrap": "agreementFormWrap_2-gVe",
	"listCampus": "listCampus_1w0fe",
	"campusItem": "campusItem_21TGm",
	"campusLabel": "campusLabel_3ZO1x"
};