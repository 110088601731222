exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".modal_css_8DWK6 {\n    display: none;\n    position: fixed;\n    z-index: 1;\n    padding-top: 100px;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n    background-color: rgb(0, 0, 0);\n    background-color: rgba(0, 0, 0, 0.8);\n}\n.modal_css_8DWK6.active__NUPh {\n    display: block;\n    z-index: 4;\n}\n.modal_css_8DWK6 .body_15AfH {\n    background-color: #fefefe;\n    margin: auto;\n    padding: 20px;\n    border: 1px solid #888;\n    width: fit-content;\n    position: relative;\n    display: inline-block;\n    left: calc(50% - 148px);\n}\n\n.modal_css_8DWK6 .body_15AfH .close_3GCOZ {\n    width: 25px;\n    height: 25px;\n    position: absolute;\n    top: -12.5px;\n    right: -12.5px;\n    border-radius: 50%;\n    background: white;\n    border: 0px;\n    opacity: 1;\n    border: 1px black solid;\n    cursor: pointer;\n}\n\n.modal_css_8DWK6 .body_15AfH .close_3GCOZ svg {\n    width: 25px;\n    height: 25px;\n    margin-top: -3px;\n    margin-left: -1px;\n}\n.modal_css_8DWK6 .body_15AfH .close_3GCOZ:hover {\n    opacity: 1;\n    background: #eee;\n}\n", ""]);

// exports
exports.locals = {
	"modal_css": "modal_css_8DWK6",
	"active": "active__NUPh",
	"body": "body_15AfH",
	"close": "close_3GCOZ"
};