exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".headerWrap_2u-11 {\n    position: relative;\n    margin-top: 40px;\n}\n\n.headerLogoImg_zhV7X {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 130px;\n}\n\n.headerTitleWrap_3BJRP {\n    font-size: 14px;\n    z-index: 1;\n}\n", ""]);

// exports
exports.locals = {
	"headerWrap": "headerWrap_2u-11",
	"headerLogoImg": "headerLogoImg_zhV7X",
	"headerTitleWrap": "headerTitleWrap_3BJRP"
};