exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".footer_1DQMb {\n    margin-top: 5px;\n    color: #ccc;\n}\n\n.footerRight_3QuDb {\n    text-align: right;\n}\n\n.footerRightNo_1iQby {\n    color: rgba(12, 12, 12, 0.5);\n}\n", ""]);

// exports
exports.locals = {
	"footer": "footer_1DQMb",
	"footerRight": "footerRight_3QuDb",
	"footerRightNo": "footerRightNo_1iQby"
};