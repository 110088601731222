exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_1pYhU {\n    padding: 0 80px;\n}\n\n.infoContainer_1pYhU > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.referalContainer_2KsIg {\n    padding-top: 30px;\n}\n\n.sectionSubList_1mrrd {\n    list-style: none;\n    position: relative;\n}\n\n.sectionListChild_2W_uE {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.sectionListChild_2W_uE input {\n    position: absolute;\n    left: 10px;\n    line-height: 20px;\n}\n\n.submitContent_14v-w {\n    padding-top: 10px;\n    font-weight: 800;\n    font-style: italic;\n}\n\n.gapSection17_2tuTG {\n    height: 20px;\n}\n\n.signatureSize_3Xn9l img {\n    max-width: 200px;\n    max-height: 80px;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_1pYhU",
	"referalContainer": "referalContainer_2KsIg",
	"sectionSubList": "sectionSubList_1mrrd",
	"sectionListChild": "sectionListChild_2W_uE",
	"submitContent": "submitContent_14v-w",
	"gapSection17": "gapSection17_2tuTG",
	"signatureSize": "signatureSize_3Xn9l"
};