exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".headerTitle_2QZh4 {\n    font-size: 25px;\n    text-align: center;\n    margin-bottom: 0;\n    position: relative;\n    margin-top: 40px;\n}\n.rowItemTitle_rBJPf {\n    font-size: 14px;\n    font-weight: bold;\n    text-align: center;\n    background: #ccc;\n    border-top: 1px solid;\n    margin: 20px 0;\n}\n.rowItemWrap_2ix1A {\n    margin-bottom: 5px;\n}\n\n.repayment__input_4TnM- {\n    display: inline-block;\n}\n\n.repayment__input_4TnM- tr:nth-child(2) {\n    display: none;\n}\n\n.institutionalRefund_Sh-Q_ {\n    margin-bottom: 0px !important;\n}\n\n.institutionalRefund_Sh-Q_ td:first-child {\n    width: 90%;\n    border: none !important;\n}\n\n.inlineInput_2irTM {\n    display: inline-block;\n    width: 150px;\n    margin: 0 5px;\n}\n.gapCalifornia_1cOCB {\n    height: 68px;\n}\n\n.gapSection3_1DZcd {\n    height: 350px;\n}\n\n.initialBox_3q6f0 > img {\n    width: 100px;\n    height: 50px;\n}\n\n.borderNone_33R_u {\n    border: none !important;\n}", ""]);

// exports
exports.locals = {
	"headerTitle": "headerTitle_2QZh4",
	"rowItemTitle": "rowItemTitle_rBJPf",
	"rowItemWrap": "rowItemWrap_2ix1A",
	"repayment__input": "repayment__input_4TnM-",
	"institutionalRefund": "institutionalRefund_Sh-Q_",
	"inlineInput": "inlineInput_2irTM",
	"gapCalifornia": "gapCalifornia_1cOCB",
	"gapSection3": "gapSection3_1DZcd",
	"initialBox": "initialBox_3q6f0",
	"borderNone": "borderNone_33R_u"
};