exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_OLwaS {\n    padding: 0 20px;\n}\n\n.infoContainer_OLwaS > h3 {\n    text-align: center;\n    font-weight: 800;\n    padding-bottom: 10px;\n}\n\n.arbitrationAgreement_1hZLM {\n    display: inline-block;\n    padding: 0 5px;\n}\n\n.arbitrationContainer_28wgs span {\n    font-size: 16px;\n}\n\n.sectionAgreement_DMzDo {\n    padding: 15px 0;\n}\n\n.gapSection5_19io7 {\n    height: 210px;\n}\n\n.initialBoxImage_3Wc7M img {\n    max-width: 100px;\n    max-height: 34px;\n    height: auto;\n    width: auto;\n}\n", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_OLwaS",
	"arbitrationAgreement": "arbitrationAgreement_1hZLM",
	"arbitrationContainer": "arbitrationContainer_28wgs",
	"sectionAgreement": "sectionAgreement_DMzDo",
	"gapSection5": "gapSection5_19io7",
	"initialBoxImage": "initialBoxImage_3Wc7M"
};