exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".agreementPosition_2YnvC {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .agreementPosition_2YnvC {\n        font-size: 7pt;\n    }\n\n    .displayNone_kBuUc {\n        display: none;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"agreementPosition": "agreementPosition_2YnvC",
	"displayNone": "displayNone_kBuUc"
};