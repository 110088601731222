exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".root_27EEi {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.input_2yRM0 {\n    position: absolute !important;\n    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */\n    clip: rect(1px, 1px, 1px, 1px);\n    padding: 0 !important;\n    border: 0 !important;\n    height: 1px !important;\n    width: 1px !important;\n    overflow: hidden;\n}\n\n.icon_3uDar {\n    display: block;\n    width: 18px;\n    height: 18px;\n    padding: 1.5px;\n    box-sizing: border-box;\n    border-radius: 4px;\n    position: relative;\n    cursor: pointer;\n    fill: transparent;\n    border: solid 2px #ff6a47;\n}\n\n.input_2yRM0:checked ~ .icon_3uDar {\n    fill: #ff6a47;\n}\n\n.input_2yRM0:disabled ~ .icon_3uDar {\n    cursor: auto;\n}\n\n.input_2yRM0:disabled ~ .icon_3uDar {\n    border-color: #e6e6e6;\n    fill: transparent;\n}\n\n.input_2yRM0:focus ~ .icon_3uDar {\n    box-shadow: 0 0 0 2px #1e90ff;\n}\n\n.text_2xNJ4 {\n    font-family: \"Open Sans\", sans-serif;\n    font-size: 12px;\n    font-weight: bold;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: 12px;\n    letter-spacing: normal;\n    color: #212121;\n    margin: 0;\n    padding: 0;\n    margin-left: 8px;\n}\n", ""]);

// exports
exports.locals = {
	"root": "root_27EEi",
	"input": "input_2yRM0",
	"icon": "icon_3uDar",
	"text": "text_2xNJ4"
};