exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_3tMFi {\n    padding: 0 80px;\n}\n\n.infoContainer_3tMFi > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.conditionsContainer_pl2dK {\n    padding-top: 5px;\n}\n\n.gapSection15_1HGag {\n    height: 55px;\n}\n\n.sectionContainer_awtop {\n    padding-top: 0px;\n}\n\n.emptyContainer_CnMOK {\n    padding-top: 100px;\n}\n\n.signatureSize_1MHxc img {\n    width: 200px;\n    height: 80px;\n}\n\n.centerText_2-abs {\n    text-align: center;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_3tMFi",
	"conditionsContainer": "conditionsContainer_pl2dK",
	"gapSection15": "gapSection15_1HGag",
	"sectionContainer": "sectionContainer_awtop",
	"emptyContainer": "emptyContainer_CnMOK",
	"signatureSize": "signatureSize_1MHxc",
	"centerText": "centerText_2-abs"
};