exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".footerWrap_2BKiO {\n    font-size: 16px;\n}\n\n.footerWrap_2BKiO p {\n    margin: 0;\n}\n\n.footerDescription_17crq {\n    font-style: italic;\n    margin-right: 50px;\n}\n\n.footerLeft_1xQ8R {\n    display: inline-block;\n    font-size: 12px;\n    margin-top: 10px;\n    margin-bottom: 10px;\n    position: relative;\n    min-height: 100%;\n}\n\n.footerRight_Hq-1t {\n    text-align: right;\n    font-size: 12px;\n}\n\n.footerBold_3ZUH0 {\n    font-weight: bold;\n    padding-left: 3px;\n    font-size: 13px;\n}\n", ""]);

// exports
exports.locals = {
	"footerWrap": "footerWrap_2BKiO",
	"footerDescription": "footerDescription_17crq",
	"footerLeft": "footerLeft_1xQ8R",
	"footerRight": "footerRight_Hq-1t",
	"footerBold": "footerBold_3ZUH0"
};