exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_O2vPI {\n    padding: 0 80px;\n}\n\n.infoContainer_O2vPI > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.conditionsContainer_1Ye4q {\n    padding-top: 5px;\n}\n\n.gapSection15_2Rctf {\n    height: 55px;\n}\n\n.signatureSize_2etc6 img {\n    max-width: 200px;\n    max-height: 80px;\n}\n\n.centerText_3UjkK {\n    text-align: center;\n}\n\n.descriptionText_3uoXz {\n    font-size: 15px !important;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_O2vPI",
	"conditionsContainer": "conditionsContainer_1Ye4q",
	"gapSection15": "gapSection15_2Rctf",
	"signatureSize": "signatureSize_2etc6",
	"centerText": "centerText_3UjkK",
	"descriptionText": "descriptionText_3uoXz"
};