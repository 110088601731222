exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_2INra {\n    padding: 0 80px;\n}\n\n.divition_pyxCl {\n    margin: 10px 0;\n    border-top: 5px solid #040404;\n}\n\n.infoContainer_2INra > div > p {\n    text-align: center;\n    font-weight: 800;\n    font-style: italic;\n}\n\n.infoContainer_2INra > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.checkBoxContent_24XdZ {\n    padding-left: 10px;\n}\n\n.creditContainer_6S2cS {\n    padding: 15px 0 0 0;\n}\n.creditContainer_6S2cS > h4 {\n    display: inline;\n    font-size: 17.5px;\n    font-weight: 800;\n}\n\n.creditContainer_6S2cS > span,\n.continue_DqfH9 {\n    font-style: italic;\n    font-size: 13px;\n}\n", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_2INra",
	"divition": "divition_pyxCl",
	"checkBoxContent": "checkBoxContent_24XdZ",
	"creditContainer": "creditContainer_6S2cS",
	"continue": "continue_DqfH9"
};