exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".titleCurve_9bYWi {\n    text-decoration: underline;\n    font-weight: 800;\n}\n", ""]);

// exports
exports.locals = {
	"titleCurve": "titleCurve_9bYWi"
};