exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".paddingFooter_BipJq {\n    padding-top: 60%;\n    padding-bottom: 30%;\n}\n\n.containerPosition_rfq7g {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n.pageSize_3btzq {\n    position: relative;\n    min-height: 1420px;\n}\n\n.paddingLeftRight_3JVYs {\n    padding-left: 30px;\n    padding-right: 30px;\n}\n\n.textCenter_29z_6 {\n    text-align: center;\n}\n\n.paddingTopContent_2gB34 {\n    padding-top: 50px;\n}\n\n.paddingToplogo_6F5MS {\n    padding-top: 70px;\n}\n\n.paddingTopSection_21ynw {\n    padding-top: 15%;\n    padding-bottom: 20px;\n}\n\n.paddingInput_1yDmV {\n    padding-top: 15%;\n}\n\n.imgWidth_oMW26 {\n    width: 300px;\n}\n\n.alignJustify_3Gly_ {\n    text-align: justify;\n}\n\n.textVariant_3t32H {\n    font-variant: normal;\n}\n\n.inputInline_3suqW {\n    display: inline-block;\n}\n\n.signatureSize_1DA-r {\n    width: 300px;\n    vertical-align: bottom;\n    margin: 0px;\n}\n\n.footerPosition_1rFgd {\n    position: absolute;\n    width: 100%;\n    bottom: 40px;\n}\n\n.inputDisplay_3y6SD {\n    display: none;\n}\n\n.breakPage_30lQy {\n    page-break-after: always;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_rfq7g {\n        font-size: 10pt;\n    }\n\n    .displayNone_3n0-S {\n        display: none;\n    }\n\n    .pageSize_3btzq {\n        min-height: 27.9cm;\n    }\n\n    .footerPosition_1rFgd {\n        bottom: 20pt;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"paddingFooter": "paddingFooter_BipJq",
	"containerPosition": "containerPosition_rfq7g",
	"pageSize": "pageSize_3btzq",
	"paddingLeftRight": "paddingLeftRight_3JVYs",
	"textCenter": "textCenter_29z_6",
	"paddingTopContent": "paddingTopContent_2gB34",
	"paddingToplogo": "paddingToplogo_6F5MS",
	"paddingTopSection": "paddingTopSection_21ynw",
	"paddingInput": "paddingInput_1yDmV",
	"imgWidth": "imgWidth_oMW26",
	"alignJustify": "alignJustify_3Gly_",
	"textVariant": "textVariant_3t32H",
	"inputInline": "inputInline_3suqW",
	"signatureSize": "signatureSize_1DA-r",
	"footerPosition": "footerPosition_1rFgd",
	"inputDisplay": "inputDisplay_3y6SD",
	"breakPage": "breakPage_30lQy",
	"displayNone": "displayNone_3n0-S"
};