exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".containerPosition_dDuNu {\n    position: relative;\n    width: 100%;\n    font-size: 15px;\n}\n\n.pageSize_3eJXK {\n    position: relative;\n    min-height: 1420px;\n}\n\n.imgWidth_1t3Os {\n    width: 250px;\n}\n\n.textCenter_1UwQj {\n    text-align: center;\n}\n\n.paddingTop_3Po0W {\n    padding-top: 40px;\n}\n\n.tableBorder_8MdlP td {\n    border: 0px !important;\n}\n\n.studentCell_3r-KB {\n    width: 60px;\n}\n\n.dateCell_3d3TA {\n    width: 90px;\n}\n\n.tableAlign_31nBo td {\n    vertical-align: bottom !important;\n}\n\n.marginNone_2uSbq {\n    margin: 0px !important;\n}\n\n.otherCell_1Hspx {\n    width: 150px;\n}\n\n.selectCell_3UG0K {\n    width: 300px;\n}\n\n.dateInputWidth_3kXkO {\n    width: 100%;\n}\n\n.footerPosition_2lygk {\n    position: absolute;\n    bottom: 0px;\n    padding-bottom: 40px;\n    width: 100%;\n    text-align: center;\n}\n\n.inputBold_2WI-F {\n    font-weight: bold;\n}\n\n.breakPage_EvmE3 {\n    page-break-after: always;\n}\n\n.inputDisplayNone_2gVHf {\n    display: none;\n}\n\n@media print {\n    @page {\n        size: letter;\n        margin: 0pt;\n    }\n\n    .containerPosition_dDuNu {\n        font-size: 7pt;\n    }\n\n    .imgWidth_1t3Os {\n        width: 150px;\n    }\n\n    .displayNone_3CpYH {\n        display: none;\n    }\n\n    .pageSize_3eJXK {\n        min-height: 27.9cm;\n    }\n\n    .paddingTop_3Po0W {\n        padding-top: 20pt;\n    }\n\n    .inputHeight_12mdw {\n        height: 20pt;\n    }\n\n    .footerPosition_2lygk {\n        padding-bottom: 10pt;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"containerPosition": "containerPosition_dDuNu",
	"pageSize": "pageSize_3eJXK",
	"imgWidth": "imgWidth_1t3Os",
	"textCenter": "textCenter_1UwQj",
	"paddingTop": "paddingTop_3Po0W",
	"tableBorder": "tableBorder_8MdlP",
	"studentCell": "studentCell_3r-KB",
	"dateCell": "dateCell_3d3TA",
	"tableAlign": "tableAlign_31nBo",
	"marginNone": "marginNone_2uSbq",
	"otherCell": "otherCell_1Hspx",
	"selectCell": "selectCell_3UG0K",
	"dateInputWidth": "dateInputWidth_3kXkO",
	"footerPosition": "footerPosition_2lygk",
	"inputBold": "inputBold_2WI-F",
	"breakPage": "breakPage_EvmE3",
	"inputDisplayNone": "inputDisplayNone_2gVHf",
	"displayNone": "displayNone_3CpYH",
	"inputHeight": "inputHeight_12mdw"
};