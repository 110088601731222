exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".infoContainer_FY8Sx {\n    padding: 0 80px;\n}\n\n.infoContainer_FY8Sx > h3 {\n    text-align: center;\n    font-weight: 800;\n}\n\n.conditionsContainer_wHt1v {\n    padding-top: 5px;\n}\n\n.gapSection19_1TUEQ {\n    height: 100px;\n}\n\n.signatureSize_2jDUB img {\n    max-width: 200px;\n    max-height: 80px;\n}\n\n.centerText_209Kb {\n    text-align: center;\n}\n\n.descriptionText_1u6R4 {\n    font-size: 15px !important;\n}\n\n.titleSize_UDdak {\n    width: 70%;\n}\n\n.cellSize_3m633 {\n    width: 80%;\n}\n\n.tablePadding_2MlIj td {\n    padding: 10px;\n}\n\n.tableBorder_16bo6 {\n    width: 100%;\n}\n\n.tableBorder_16bo6 td {\n    border: none !important;\n}\n\n.inputDisplay_34Mhn {\n    display: none;\n}", ""]);

// exports
exports.locals = {
	"infoContainer": "infoContainer_FY8Sx",
	"conditionsContainer": "conditionsContainer_wHt1v",
	"gapSection19": "gapSection19_1TUEQ",
	"signatureSize": "signatureSize_2jDUB",
	"centerText": "centerText_209Kb",
	"descriptionText": "descriptionText_1u6R4",
	"titleSize": "titleSize_UDdak",
	"cellSize": "cellSize_3m633",
	"tablePadding": "tablePadding_2MlIj",
	"tableBorder": "tableBorder_16bo6",
	"inputDisplay": "inputDisplay_34Mhn"
};