exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".header_1mZZk {\n    padding: 0 0 10px 0;\n    width: 100%;\n}\n\n.sectionHeader_zKUtB {\n    display: inline-block;\n    width: 100%;\n    position: relative;\n    padding-bottom: 30px;\n}\n\n.sectionHeader_zKUtB img {\n    height: 60px;\n}\n\n.titlePosition_C7Dot {\n    position: absolute;\n    width: 100%;\n    top: 10px;\n}", ""]);

// exports
exports.locals = {
	"header": "header_1mZZk",
	"sectionHeader": "sectionHeader_zKUtB",
	"titlePosition": "titlePosition_C7Dot"
};