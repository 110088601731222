exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".enrollmentAgreement_1t8Xt input[type] {\n    height: 25px;\n    line-height: 32px;\n    font-size: 12px;\n    padding: 0 5px;\n    font-family: inherit;\n    color: #000000;\n    background-color: #fff;\n    background-image: none;\n    border: 0;\n    border-bottom: 1px solid #ccc;\n}\n\n.enrollmentAgreement_1t8Xt select {\n    height: 28px;\n    line-height: 32px;\n    font-size: 14px;\n    padding: 0 5px;\n    font-family: inherit;\n    color: #000000;\n    background-color: #fff;\n    background-image: none;\n    border: 1px solid #ccc;\n}\n\n.enrollmentAgreement_1t8Xt input:disabled {\n    background-color: #fff !important;\n    color: #000000 !important;\n    opacity: 1 !important;\n}\n\n.enrollmentAgreement_1t8Xt select:disabled {\n    background-color: #fff !important;\n    color: #000000 !important;\n    opacity: 1 !important;\n}\n\n.enrollmentAgreement_1t8Xt label {\n    font-size: 12px !important;\n}\n", ""]);

// exports
exports.locals = {
	"enrollmentAgreement": "enrollmentAgreement_1t8Xt"
};